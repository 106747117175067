import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

import { DialogButton, BannerType, ComponentController } from 'components/common';
import { GeneralDetailsFields } from '../Helpers';
import { addAssured } from '../SubmissionWizard.api';

const assuredMaxLength = 130;

export const AssuredPopup = ({
  popupState,
  onPopupSubmit,
  submitData,
  setPopupState,
  setFormState,
  setValidationValue,
  setBannerState,
  setLoading
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { assured: popupState.newValue }
  });

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onAssuredSubmit = useCallback(response => {
    let assured = {
      assured: response.data?.assured,
      assuredId: response.data?.assuredId,
      effectiveFromDate: response.data?.effectiveFromDate,
      effectiveToDate: response.data?.effectiveToDate
    };

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        assured,
        secondAssured: previous.fields.isDualStamp ? assured : null
      }
    }));

    setValidationValue("assured", assured, { shouldValidate: true });
  }, [setFormState, setValidationValue]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    let assuredName = popupData.assured;
    setLoading(true);

    let response = await addAssured({ assured: assuredName });
    if (response.success) {
      if (onPopupSubmit) {
        onPopupSubmit(response, submitData);
      } else {
        onAssuredSubmit(response);
      }
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }

    setLoading(false);
  }, [handleClose, submitData, onAssuredSubmit, onPopupSubmit, setBannerState, setLoading]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {assuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="assured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="assured-dialog-title">Add a new Assured to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="assured"
            control={control}
            required
            rules={{ maxLength: assuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};