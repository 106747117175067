import React, { useCallback, useEffect } from 'react';

import { TextField } from '@material-ui/core';
import { TotalPoliciesValidRange } from '../Helpers';
import { ComponentController } from 'components/common';

const TotalPoliciesField = ({
  name,
  label,
  disabled,
  required,
  control,
  value,
  errors,
  dualStampSubmission,
  handleChange,
  setFormState,
  setValidationValue,
  getRequiredError
}) => {
  useEffect(() => {
    setValidationValue(name, value, { shouldValidate: true });
  }, [dualStampSubmission, name, value, setValidationValue]);

  const onTotalNumberOfPolicyReferencesChange = useCallback(event => {
    let value = event.target?.value;
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value }
    }));
  }, [name, setFormState]);

  const getMinimumValue = useCallback(() => {
    return dualStampSubmission ? TotalPoliciesValidRange.minDualStamp : TotalPoliciesValidRange.min;
  }, [dualStampSubmission]);

  const validateTotalPolicies = useCallback(totalPolicies => {
    let minValue = getMinimumValue();
    return totalPolicies <= TotalPoliciesValidRange.max && totalPolicies >= minValue;
  }, [getMinimumValue]);

  const getTotalPoliciesError = name => {
    let minValue = dualStampSubmission ? TotalPoliciesValidRange.minDualStamp : TotalPoliciesValidRange.min;
    return errors[name]?.type === 'validate' &&
      <span role="alert">"{label}" must be between {minValue} and {TotalPoliciesValidRange.max}</span>;
  }

  return (
    <ComponentController
      name={name}
      control={control}
      required={required}
      rules={{ validate: validateTotalPolicies }}
      render={({ field: { name, onChange, onBlur } }) =>
        <TextField
          id={name}
          name={name}
          type="number"
          label={label}
          fullWidth
          disabled={disabled}
          value={value}
          onChange={e => {
            if (handleChange) {
              handleChange(e);
            }

            onTotalNumberOfPolicyReferencesChange(e);
            onChange(e);
          }}
          onBlur={onBlur}
          error={!!errors[name]}
          helperText={getRequiredError(name) || getTotalPoliciesError(name)}
        />
      }
    />
  );
}

export default TotalPoliciesField;