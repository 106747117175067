import React, {useCallback} from 'react';

import { Grid } from '@material-ui/core';

import { Datasource } from 'Api';
import { PickerComponent, ComponentController } from 'components/common';
import { GeneralDetailsFields } from 'components/SubmissionWizard/Helpers';
import { getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';
import { isPolicyRenewedWithCustomLink } from 'components/SubmissionWizard/Helpers';
import '../../../GeneralDetails.css';

export const PolicyReferenceControl = ({
  controlName,
  fields,
  control,
  errors,
  lookupType,
  requestData,
  forceClearDatasourceState,
  setBannerState,
  setLoading,
  setFormState,
  setValidationValue,
  getAssuredData,
  setMasterAssuredData,
  setSecondMasterAssuredData,
  getValue,
  onAssuredPolicyChanged,
  disabled,
  abortController,
  setAbortController
}) => {
  const defaultName = 'assured';
  const defaultValue = fields.assured;

  const value = !!getValue ? getValue(fields) : defaultValue;

  const onAssuredChange = useCallback(async assuredRow => {
    const getAssured = !!getAssuredData ? getAssuredData : () => ({});     

    if (getAssured && assuredRow) {
      let renewed = !!assuredRow?.renewedToReference;

      if (!renewed) {
        setLoading(true);
        let response = await getPolicy(assuredRow?.policyId);
        setLoading(false);
      
        renewed = isPolicyRenewedWithCustomLink(response.data);
      }  
      assuredRow.renewed = renewed;
    }

    if (setMasterAssuredData) {
      await setMasterAssuredData(assuredRow);
    }
    if (setSecondMasterAssuredData) {
      await setSecondMasterAssuredData(assuredRow);
    }
     
    setFormState(previous => ({
        ...previous,
        fields: {
          ...previous.fields,
          ...getAssured(previous, assuredRow)
        }
    }));
  }, [getAssuredData, setFormState]);

  const getAssuredRequiredError = (errorAlias, lookupType) => {
    return errors[errorAlias]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[lookupType]}" is required</span>;
  };
  
  return (
    <Grid item>
      <ComponentController
        name={controlName ?? defaultName}
        control={control}
        required
        render={({ field: { name, onBlur, onChange } }) =>
          <PickerComponent
            label={GeneralDetailsFields[lookupType]}
            fieldName={name}
            value={value ?? ''}
            datasource={Datasource.assuredWithMajorClass}
            abortController={abortController}
            disabled={disabled}
            requestData={requestData}
            error={!!errors[name]}
            errorText={getAssuredRequiredError(name, lookupType)}
            config={{ pickerViewClass: "assured-picker-view" }}
            forceClearDatasource={forceClearDatasourceState}
            setBannerState={setBannerState}
            setLoading={setLoading}
            setFormState={setFormState}
            setValidationValue={setValidationValue}
            onChange={value => { onAssuredChange(value); onChange(value); onAssuredPolicyChanged && onAssuredPolicyChanged(value); }}
            onBlur={onBlur}
            setAbortController={setAbortController }
            OptionsHeader={({ className }) => {
              return (
                <Grid container direction="row" spacing={0} className={className}>
                  <Grid item xs={2}><div>Organisation Name</div></Grid>
                  <Grid item xs={1}><div>Organisation Type</div></Grid>
                  <Grid item xs={1}><div>Entity</div></Grid>
                  <Grid item xs={1}><div>Major Class</div></Grid>
                  <Grid item xs={1}><div>YOA</div></Grid>
                  <Grid item xs={1}><div>Policy Reference</div></Grid>
                  <Grid item xs={1}><div>Policy Status</div></Grid>
                  <Grid item xs={1}><div>Country</div></Grid>
                  <Grid item xs={1}><div>UMR</div></Grid>
                  <Grid item xs={1}><div>Dec Ref</div></Grid>
                  <Grid item xs={1}><div>Placing Basis</div></Grid>
                </Grid>
              );
            }}
            OptionView={({ option, className }) => {
              return (
                <Grid container direction="row" spacing={0} className={className}>
                  <Grid item xs={2}><div>{option.assured}</div></Grid>
                  <Grid item xs={1}><div>{option.assuredType}</div></Grid>
                  <Grid item xs={1}><div>{option.entity}</div></Grid>
                  <Grid item xs={1}><div>{option.majorClassCode}</div></Grid>
                  <Grid item xs={1}><div>{option.yearOfAccount}</div></Grid>
                  <Grid item xs={1}><div>{option.policyReferenceNumber}</div></Grid>
                  <Grid item xs={1}><div>{option.policyStatus}</div></Grid>
                  <Grid item xs={1}><div>{option.country}</div></Grid>
                  <Grid item xs={1}><div>{option.umr}</div></Grid>
                  <Grid item xs={1}><div>{option.declarationReference}</div></Grid>
                  <Grid item xs={1}><div>{option.placingBasis}</div></Grid>
                </Grid>
              );
            }}
          />
        }
      />
    </Grid>
  );
}
