import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { TextField, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';

import { DialogButton, BannerType, ComponentController } from 'components/common';
import { GeneralDetailsFields } from '../Helpers';
import { addReassured } from '../SubmissionWizard.api';

const reassuredMaxLength = 130;

export const ReassuredPopup = ({
  popupState,
  setPopupState,
  setFormState,
  setValidationValue,
  setBannerState,
  setLoading
}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: "onTouched",
    defaultValues: { reassured: popupState.newValue }
  });

  const handleClose = useCallback(
    () => setPopupState(previous => ({ ...previous, show: false })),
    [setPopupState]);

  const onSubmit = useCallback(async popupData => {
    handleClose();

    let reassuredName = popupData.reassured;
    setLoading(true);

    let response = await addReassured({ reassured: reassuredName });
    let reassured = reassuredName;

    if (response.success) {
      setFormState(previous => ({
        ...previous,
        fields: {
          ...previous.fields,
          reassured,
          secondReassured: previous.fields.isDualStamp ? reassured : null
        }
      }));

      setValidationValue("reassured", reassured, { shouldValidate: true });
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }

    setLoading(false);
  }, [setFormState, setValidationValue, handleClose, setBannerState, setLoading]);

  const getRequiredValidationError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getMaxLengthValidationError = name => {
    return errors[name]?.type === 'maxLength' &&
      <span role="alert">"{GeneralDetailsFields[name]}" maximum length is {reassuredMaxLength} characters</span>;
  }

  return (
    <Dialog open={popupState.show} onClose={handleClose} aria-labelledby="reassured-dialog-title">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="reassured-dialog-title">Add a new Reassured to Sequel Eclipse</DialogTitle>
        <DialogContent>
          <ComponentController
            name="reassured"
            control={control}
            required
            rules={{ maxLength: reassuredMaxLength }}
            render={({ field: { name, ...rest } }) =>
              <TextField
                {...rest}
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                margin="dense"
                fullWidth
                error={!!errors[name]}
                helperText={getRequiredValidationError(name) || getMaxLengthValidationError(name)}
              />
            }
          />
        </DialogContent>
        <DialogActions className="dialog-buttons">
          <DialogButton onClick={handleClose}>Cancel</DialogButton>
          <DialogButton type="Submit">Add</DialogButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};