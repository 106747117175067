import React, { useCallback, useState } from 'react';

import { FormControl, Grid, TextField } from '@material-ui/core';

import { Dropdown, FormControlWrapper, SwitchComponent } from 'components/common';
import { createMajorClassStructure, S4000Entity, dualStampEntity, GeneralDetailsFields, TotalNumberOfPolicyReferencesName, HidacEntity } from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { AssuredAndPolicyReferenceControl, SecondAssuredAndPolicyReferenceControl } from './common';
 
import '../GeneralDetails.css';

const CancellationsSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  setForceClearDatasourceState,
  resetFieldValidationState,
  modellingMajorClasses
}) => {
  const [abortController, setAbortController] = useState(new AbortController());

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const onDualStampSwitch = useCallback(checked => {
    resetFieldValidationState("assured");
    resetFieldValidationState("secondAssured");
    setForceClearDatasourceState({ "assured": true });

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        _assuredOrReassured: '',
        _secondAssuredOrReassured: '',
        isDualStamp: checked,
        businessEntity: checked ? dualStampEntity : '',
        majorClass: null,
        assured: null,
        reassured: '',
        policyId: null,
        policyReferenceNumber: '',
        secondAssured: null,
        secondReassured: '',
        secondPolicyId: null,
        secondPolicyReferenceNumber: '',
        renewedPolicyS4000: null,
        renewedPolicyHIDAC: null,
        totalNumberOfPolicyReferences: checked ? 2 : 1
      }
    }));

    abortController.abort();
  }, [abortController, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);
    let _prevMajorclassCode = true;

    setFormState(previous => {
       const prevMajorclassCode = previous.fields.majorClass?.majorClassCodes?.includes(majorClass?.majorClassCodes[0]);
      _prevMajorclassCode = prevMajorclassCode;

      if (!_prevMajorclassCode) {
        setForceClearDatasourceState({ "secondAssured": true });
        resetFieldValidationState("secondAssured");
      }
        const _secondAssured = prevMajorclassCode ? previous.fields.secondAssured : null;
        const secondAssuredOrReassured = prevMajorclassCode ? previous.fields._secondAssuredOrReassured : '';
        const _secondPolicyReferenceNumber = prevMajorclassCode ? previous.fields.secondPolicyReferenceNumber : '';
        const _secondPolicyId = prevMajorclassCode ? previous.fields.secondPolicyId : null;
        const _secondReassured = prevMajorclassCode ? previous.fields.secondReassured : null;
        const _renewedPolicyHIDAC = prevMajorclassCode ? previous.fields.renewedPolicyHIDAC : null;
        return {
          ...previous,
          fields: {
            ...previous.fields,
            _secondAssuredOrReassured: secondAssuredOrReassured,
            majorClass: majorClass,
            secondAssured: _secondAssured,
            secondReassured: _secondReassured,
            secondPolicyId: _secondPolicyId,
            secondPolicyReferenceNumber: _secondPolicyReferenceNumber,
            renewedPolicyHIDAC: _renewedPolicyHIDAC,
            requiredModellingPriority: majorClass?.isModellingPriority
              ? previous.dropdownsData.modellingPriorities[0]
              : ''
          }
        }
      });
      abortController.abort();
  }, [abortController, dropdownsData.modellingPriorities, setFormState, resetFieldValidationState, setForceClearDatasourceState]);

    const getRequiredError = name => {
      return errors[name]?.type === 'required' &&
        <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
    }

    const renderDualStampSwitch = () => {
      const name = "dualStampSwitch";
      let isDualStampVisible = [S4000Entity.DisplayValue, HidacEntity.DisplayValue].every((el) => {
        return dropdownsData.entitiesForFilter.includes(el)
      });
      
      return isDualStampVisible && (
        <Grid item>
          <FormControlWrapper
            control={
              <SwitchComponent
                name={name}
                checked={fields.isDualStamp}
                onChange={e => onDualStampSwitch(e.target?.checked)}
              />
            }
            label={GeneralDetailsFields.dualStampSwitch}
            labelPlacement="start"
          />
        </Grid>
      );
    }

    const renderModellingPriority = () => {
      let name = "requiredModellingPriority";
      return fields.majorClass?.isModellingPriority &&
        (
          <Grid item>
            <FormControl fullWidth>
              <Dropdown
                id={name}
                label={GeneralDetailsFields.requiredModellingPriority}
                value={fields.requiredModellingPriority}
                data={dropdownsData.modellingPriorities}
                onChange={e => onFieldChange(name, e.target.value)}
              />
            </FormControl>
          </Grid>
        );
    }

    const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
      return (<AssuredAndPolicyReferenceControl
        lookupType={lookupType}
        errors={errors}
        control={control}
        fields={fields}
        onChange={value => onAssuredPolicyChanged(value)}
        additionalRequestData={{ businessEntities: fields.isDualStamp ? [S4000Entity.DisplayValue] : dropdownsData.entitiesForFilter }}
        setFormState={setFormState}
        setValidationValue={setValidationValue}
        setLoading={setLoading}
        setBannerState={setBannerState}
        forceClearDatasourceState={forceClearDatasourceState}
        forceEnabled={true}
        includePlacingBasis={true}
        abortController={abortController}
        setAbortController={setAbortController}
      />)
    }

    const renderAssured = (assuredType = "assured") => {
      let name = "assured";
      return (
        <Grid item>
          <TextField
            id={name}
            name={name}
            fullWidth
            disabled
            label={GeneralDetailsFields[assuredType]}
            value={fields.assured?.assured ?? fields.reassured ?? ""}
          />
        </Grid>
      );
    }

    const renderPolicyReferenceNumber = (policyReferenceNumberType = "policyReferenceNumber") => {
      let name = "policyReferenceNumber";
      return (
        <Grid item>
          <TextField
            id={name}
            name={name}
            fullWidth
            disabled
            label={GeneralDetailsFields[policyReferenceNumberType]}
            value={fields.policyReferenceNumber}
          />
        </Grid>
      );
    }

    const renderSecondLookupForAssuredAndPolicyReference = (lookupType = "secondLookupForAssuredAndPolicyReference") => {
      return (fields.isDualStamp && <SecondAssuredAndPolicyReferenceControl
        lookupType={lookupType}
        errors={errors}
        control={control}
        fields={fields}
        additionalRequestData={{ businessEntities: ["HDAC", "IDAC"] }}
        setFormState={setFormState}
        setValidationValue={setValidationValue}
        setLoading={setLoading}
        setBannerState={setBannerState}
        forceClearDatasourceState={forceClearDatasourceState}
        includePlacingBasis={true}
      />)
    }

    const renderSecondAssured = () => {
      let name = "secondAssured";
      return (
        fields.isDualStamp &&
        <Grid item>
          <TextField
            id={name}
            name={name}
            fullWidth
            disabled
            label={GeneralDetailsFields[name]}
            value={fields.secondAssured?.assured ?? fields.secondReassured ?? ""}
          />
        </Grid>
      );
    }

    const renderSecondPolicyReferenceNumber = () => {
      let name = "secondPolicyReferenceNumber";
      return (
        fields.isDualStamp &&
        <Grid item>
          <TextField
            id={name}
            name={name}
            fullWidth
            disabled
            label={GeneralDetailsFields[name]}
            value={fields.secondPolicyReferenceNumber}
          />
        </Grid>
      );
    }

    const renderTotalNumberOfReferences = () => {
      return (
        <Grid item>
          <TotalPoliciesField
            name={TotalNumberOfPolicyReferencesName}
            required={true}
            label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
            control={control}
            value={fields.totalNumberOfPolicyReferences}
            errors={errors}
            setFormState={setFormState}
            setValidationValue={setValidationValue}
            getRequiredError={getRequiredError}
          />
        </Grid>
      );
    }

    return <>
      {renderDualStampSwitch()}
      {renderModellingPriority()}
      {renderLookupForAssuredAndPolicyReference(fields.isDualStamp ? "lookupForAssuredAndPolicyReference4000" : "lookupForAssuredAndPolicyReference")}
      {renderPolicyReferenceNumber(fields.isDualStamp ? "policyReferenceNumber4000" : "policyReferenceNumber")}
      {renderAssured(fields.isDualStamp ? "assuredOrMasterAssured4000" : "assured")}
      {renderSecondLookupForAssuredAndPolicyReference()}
      {renderSecondPolicyReferenceNumber()}
      {renderSecondAssured()}
      {renderTotalNumberOfReferences()}
    </>;
  }

  export default CancellationsSection;
