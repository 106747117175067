export const DocumentTypes = {
  Submissions: "Submissions",
  Cancellations: "Cancellations",
  Endorsements: "Endorsements",
  EpiUpdate: "EPI Update",
  FirmOrder: "Firm Order",
  FirmOrderOnly: "Firm Order Only",
  ModellingSubmission: "Modelling Submission",
  ModellingInfo: "Modelling Info",
  PeerReviewOnly: "Peer Review Only",
  QCError: "QC Error",
  Quote: "Quote",
  BulkItem: "Bulk Item",
  BordereauxBooking: "Bordereaux Booking",
  AccountsReceivable: "Accounts Receivable"
};

export const PlacingBases = {
  MasterBinder: "MB",
  Binder: "B",
  BinderNonProportionalRI: "BNP",
  BinderProportionalRI: "BP",
  Declaration: "DEC",
  LineSlip: "L",
  LineslipProportionalRI: "LP",
  LineslipNonProportionalRI: "LNP",
  Consortium: "C",
  ConsortiaNonProportionalRI: "CNP",
  ConsortiaProportionalRI: "CP",
  FacultativeCover: "FC",
  ReportingReinsuranceMaster: "RIM",
  NonProportionalReportingReinsuranceMaster: "NRM",
  ProportionalReportingReinsuranceMaster: "PRM"
};

export const RenewDeclaration = {
  ShortDeclaration: "eDSUC",
  LongDeclaration: "eFRD"
}

export const RenewalLinkReference = "renewal";

export const CustomLinkType = "custom";

export const DeclarationPlacingType = "DEC";

export const ReassuredPlacingBases = ["F", "FP", "XSL", "QS", "XSR", "SL", "NRM", "PRM"];


export const ModellingPriorities = ["No Modelling Required", "Low", "Medium", "High"];

export const MajorClassCodes = {
  MA: "MA",
  PR: "PR"
};

export const OrganisationTypes = {
  Assured: "assured",
  Reassured: "reassured",
  Obligor: "obligor",
  Coverholder: "coverholder"
};

export const GeneralDetailsFields = {
  criticalMonthEndTask: "Critical Month End Task",
  policyEntryInformation: "Policy Entry Information",
  documentType: "Type Of Document",
  majorClass: "Major Class",
  businessEntity: "Business Entity",
  underwriter: "Underwriter",
  yoa: "YOA",
  renewedPolicy: "Renewed Policy",
  expiringPolicyReferenceNumber: "Expiring Policy Reference",
  policyReferenceNumber: "Policy Reference Number",
  policyReferenceNumber4000: "Policy Reference Number (4000)",
  secondPolicyReferenceNumber: "Policy Reference Number (HIDAC)",
  masterPolicyReferenceNumber: "Master Policy Reference Number",
  masterPolicyReferenceNumber4000: "Master Policy Reference Number (S4000)",
  secondMasterPolicyReferenceNumber: "Master Policy Reference Number (HIDAC)",
  totalNumberOfPolicyReferences: "Total Number of Policy References/Declarations",
  totalNumberOfMonthsRequired: "Total Number of Months Required",
  conditionCode: "Condition Code",
  placingBasis: "Placing Basis",
  broker: "Broker",
  brokerContact: "Broker Contact",
  declineImmediately: "Decline Immediately",
  lookupForAssuredAndPolicyReference: "Lookup for (Assured / Reassured / Policy Reference)",
  lookupForAssuredAndPolicyReference4000: "Lookup for Assured / Reassured / Policy Reference (4000)",
  secondLookupForAssuredAndPolicyReference: "Lookup for Assured / Reassured / Policy Reference (HIDAC)",
  lookupForMasterAssuredAndMasterPolicyReference: "Lookup for Master (Assured / Reassured / Policy Reference)",
  lookupForMasterAssuredAndMasterPolicyReference4000: "Lookup for Master (Assured / Reassured / Policy Reference) S4000",
  secondLookupForMasterAssuredAndMasterPolicyReference: "Lookup for Master (Assured / Reassured / Policy Reference) HIDAC",
  assured: "Assured / Reassured",
  assuredOrMasterAssured: "Assured / Master Assured",
  assuredOrMasterAssured4000: "Assured / Reassured / Master Assured (4000)",
  secondAssured: "Assured / Reassured / Master Assured (HIDAC)",
  _masterAssuredOrMasterReassured: "Master Assured / Reassured",
  _masterAssuredOrMasterReassured4000: "Master Assured / Reassured (S4000)",
  _secondMasterAssuredOrMasterReassured: "Master Assured / Reassured (HIDAC)",
  declarationAssured: "Declaration Assured",
  reassured: "Reassured",
  coverHolder: "Coverholder",
  eplacement: "E placement",
  operatingTerritory: "Operating Territory",
  typeOfModellingRisk: "Type Of Modelling Risk",
  requiredModellingPriority: "Required Modelling Priority",
  typeOfBordereaux: "Type of Bordereaux",
  inceptionDate: "Inception Date",
  pricingNotRequired: "Pricing Not Required",
  eeaRisk: "EEA Risk",
  contractBasis: "Contract Basis",
  premiumBearing: "Premium Bearing",
  justificationForEpiUpdate: "Justification for EPI Update",
  finalPricingCompleted: "Has Final Pricing Been Completed?",
  peerReviewNotRequired: "Peer Review not Required",
  sendTaskToOperations: "Send task to Operations for manual reference creation",
  expiringReferenceSwitch: "Expiring Policy Reference/Programme Reference",
  expiringPolicyReference: "Expiring Policy Reference",
  expiringProgrammeReference: "Expiring Programme Reference",
  dualStampSwitch: "Dual Stamp",
  periodMonth: "Month of Period",
  periodYear: "Year of Period",
  commentsBordereaux: "Comments"
};

export const LineOfBusinessFields = {
  policyStructure: "Structure",
  totalNumberOfPolicyReferences: "Total Number of Policy References",
  obligor: "Obligor",
  periodBasis: "Period Basis",
  requiredModellingPriority: "Required Modelling Priority"
};

export const TotalPoliciesValidRange = { min: 1, max: 30, minDualStamp: 2 };

export const BannerValidationMessage = "Form contains validation errors. Please provide valid information.";
export const AssuredValidationMessage = "You cannot submit this task until the Assured has been populated.";

export const TotalNumberOfPolicyReferencesName = "totalNumberOfPolicyReferences";
export const TotalNumberOfMonthsRequiredName = "Total Number of Months Required";

export const S4000Entity = { Value: "S4000", DisplayValue: "4000" };
export const HidacEntity = { Value: "HIDAC", DisplayValue: "HDAC" };
export const S1947Entity = { Value: "S1947", DisplayValue: "1947" };
export const DualStampEntity = { Value: "Dual Stamp S4000/HIDAC", DispalyValue: "Dual Stamp 4000/HIDAC"}

export const DefauldUnderwriter = {
  Abbreviation: "XX",
  RenewalYear: 2023
};

export const UmrField = {
  MinLength: 4,
  MaxLength: 16
}