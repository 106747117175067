import { authorizedApiClient } from "Api";
import { StatusCodes } from "http-status-codes";
import { isArray } from "lodash";

import { cleanseRequest } from "../../Helpers";

const prefix = "api/hamiltonInternational";

const api = {
  submission: {
    init: `${prefix}/submission/init`,
    submit: `${prefix}/submission/submit`
  },
  assured: {
    add: `${prefix}/assured/add`
  },
  reassured: {
    add: `${prefix}/reassured/add`
  },
  classOfBusiness: {
    get: `${prefix}/classesOfBusiness/GetClassesOfBusiness`,
    modellingMajorClasses: `${prefix}/classesOfBusiness/GetModellingMajorClasses`
  },
  policy: {
    get: `${prefix}/policy/{id}`,
    getPoliciesToRenew: `${prefix}/policy/policiesToRenew/{policyId}`
  }
}

export const fetchInitialFormData = async () => {
  try {
    let response = await authorizedApiClient.get(api.submission.init);
    return {
      success: true,
      data: response.data
    };
  } catch (error) {
    if (error.response.status === StatusCodes.FORBIDDEN) {
      const message = "You do not have permission to access this application, please contact your manager to get access.";
      return {
        success: false,
        errorMessage: error.response.data ?? message,
        permissionError: true
      };
    }

    return {
      success: false,
      errorMessage: "Error occurred during the page loading. Please, try again."
    };
  }
}

export const addAssured = async request => {
  try {
    let response = await authorizedApiClient.post(api.assured.add, request);

    return {
      success: true,
      data: response.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred. Assured is not added. Please, try again later."
    };
  }
}

export const addReassured = async request => {
  try {
    let response = await authorizedApiClient.post(api.reassured.add, request);

    return {
      success: true,
      data: response.data
    };
  } catch {
    return {
      success: false,
      errorMessage: "Error occurred. Reassured is not added. Please, try again later."
    };
  }
}

export const getClassesOfBusiness = async (uwInitials, yoa, entity) => {
  try {
    let request = `${api.classOfBusiness.get}?uwInitials=${uwInitials}&yoa=${yoa}`;
    if (entity) {
      request += `&entity=${entity}`;
    }
    let response = await authorizedApiClient.get(request);

    return {
      success: true,
      data: response.data
    };
  } catch (e){
    return {
      success: false,
      errorMessage: "Error occurred. Line Of Business Data is not available. Please, try again later."
    };
  }
}

export const getModellingMajorClasses = async () => {
  try {
    let request = `${api.classOfBusiness.modellingMajorClasses}`;
    let response = await authorizedApiClient.get(request);

    return {
      success: true,
      data: response.data
    };
  } catch (e) {
    return {
      success: false,
      errorMessage: "Error occurred. Modelling Major Classes data is not available. Please, try again later."
    };
  }
}

export const getPolicy = async policyId => {
  try {
    let request = `${api.policy.get.replace("{id}", policyId)}`;
    let response = await authorizedApiClient.get(request);

    return {
      success: true,
      data: response.data
    };
  } catch (e) {
    return {
      success: false,
      errorMessage: "Error occurred. Policy information is not retrieved. Please, try again later."
    };
  }
}

export const getRenewPolicies = async policyId => {
  try {
    let request = `${api.policy.getPoliciesToRenew.replace("{policyId}", policyId)}`;
    let response = await authorizedApiClient.get(request);

    return {
      success: true,
      data: response.data
    };
  } catch (e) {
    return {
      success: false,
      errorMessage: "Error occurred. Renewals information is not retrieved. Please, try again later."
    };
  }
}

export const submitPolicy = async (form, files) => {
  let request = { ...cleanseRequest(form), files };
  let path = api.submission.submit;

  try {
    await authorizedApiClient.post(path, request);
    return { success: true };
  } catch (e) {
    const validationErrorMessage = getValidationErrorMessage(e);
    return {
      success: false,
      errorMessage: validationErrorMessage ? validationErrorMessage : 'Submission is failed. Errors occurred'
    };
  }
}

const getValidationErrorMessage = errorResponse => {
  if (errorResponse.response?.status !== 400) return '';

  if (isArray(errorResponse.response.data)) {
    const messages = errorResponse.response.data?.map(error => error.errorMessage);
    return messages?.length ? messages.join('; ') : '';
  }

  return errorResponse.response.data?.title;
}