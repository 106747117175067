import React, { useCallback } from 'react';

import { Grid, TextField } from '@material-ui/core';

import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { AssuredAndPolicyReferenceControl } from './common';
import { GeneralDetailsFields, PlacingBases, TotalNumberOfPolicyReferencesName, createMajorClassStructure } from 'components/SubmissionWizard/Helpers';

import '../GeneralDetails.css';

const PeerReviewOnlySection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  modellingMajorClasses
}) => {
  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        majorClass: majorClass
      }
    }));
  }, [setFormState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    return (<AssuredAndPolicyReferenceControl
      lookupType={lookupType}
      errors={errors}
      control={control}
      fields={fields}
      onChange={value => onAssuredPolicyChanged(value)}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      setLoading={setLoading}
      setBannerState={setBannerState}
      forceClearDatasourceState={forceClearDatasourceState}
      forceEnabled={true}
      includePlacingBasis={true}
      additionalRequestData={{ businessEntities: dropdownsData.entitiesForFilter }}
    />)
  }

  const renderAssured = () => {
    let name = "assured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          required={true}
          control={control}
          value={fields.totalNumberOfPolicyReferences}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = () => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields.policyReferenceNumber}
        />
      </Grid>
    );
  }

  const renderMasterPolicyReferenceNumber = () => {
    let name = "masterPolicyReferenceNumber";
    let render = fields.placingBasisCode === PlacingBases.Declaration;
    return render && (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields.masterPolicyReferenceNumber}
        />
      </Grid>
    );
  }

  return <>
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
    {renderTotalNumberOfReferences()}
    {renderMasterPolicyReferenceNumber()}
  </>;
}

export default PeerReviewOnlySection;