import React, { useCallback, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import {
  ComponentController,
  PickerComponent,
  InformationMessage
} from 'components/common';
import { convertToArray, GeneralDetailsFields, HidacEntity, TotalNumberOfPolicyReferencesName } from 'components/SubmissionWizard/Helpers';
import { createMajorClassStructure, S4000Entity } from 'components/SubmissionWizard/Helpers';
import TotalPoliciesField from 'components/SubmissionWizard/Fields/TotalPoliciesField';
import { AssuredAndPolicyReferenceControl } from './common';

import '../GeneralDetails.css';

const AccountsReceivableSection = ({
  fields,
  dropdownsData,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setValidationValue,
  setLoading,
  setBannerState,
  setForceClearDatasourceState,
  resetFieldValidationState,
  modellingMajorClasses
}) => {
 
  const maxCommentsLength = 1000;

  const onAssuredPolicyChanged = useCallback(assured => {
    let majorClass = createMajorClassStructure(assured, modellingMajorClasses);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        majorClass: majorClass,
        requiredModellingPriority: majorClass?.isModellingPriority
          ? previous.dropdownsData.modellingPriorities[0]
          : ''
      }
    }));

    resetFieldValidationState("secondAssured");
    setForceClearDatasourceState({ "secondAssured": true });
  },[setFormState, resetFieldValidationState, setForceClearDatasourceState]);

  
  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value ?? '' }
    }));
  }, [setFormState]);

  const renderLookupForAssuredAndPolicyReference = (lookupType = "lookupForAssuredAndPolicyReference") => {
    let businessEntities = [HidacEntity.DisplayValue].filter((el) => {
      return dropdownsData.entitiesForFilter.includes(el)
    });

    return (
      <AssuredAndPolicyReferenceControl
        lookupType={lookupType}
        errors={errors}
        control={control}
        fields={fields}
        onChange={value => onAssuredPolicyChanged(value)}
        additionalRequestData={{ businessEntities: fields.isDualStamp ? [S4000Entity.DisplayValue] : businessEntities }}
        setFormState={setFormState}
        setValidationValue={setValidationValue}
        setLoading={setLoading}
        setBannerState={setBannerState}
        forceClearDatasourceState={forceClearDatasourceState}
        forceEnabled={true}
        includePlacingBasis={true}
      />
    );
  }

  const renderAssured = (assuredType = "assured") => {
    let name = "assured";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[assuredType]}
          value={fields.assured?.assured ?? fields.reassured ?? ""}
        />
      </Grid>
    );
  }

  const renderPolicyReferenceNumber = (policyReferenceNumberType = "policyReferenceNumber") => {
    let name = "policyReferenceNumber";

    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[policyReferenceNumberType]}
          value={fields.policyReferenceNumber}
        />
      </Grid>
    );
  }

  const renderTotalNumberOfReferences = () => {
    return (
      <Grid item>
        <TotalPoliciesField
          name={TotalNumberOfPolicyReferencesName}
          label={GeneralDetailsFields[TotalNumberOfPolicyReferencesName]}
          required={true}
          control={control}
          value={fields.totalNumberOfPolicyReferences}
          errors={errors}
          setFormState={setFormState}
          setValidationValue={setValidationValue}
          getRequiredError={getRequiredError}
        />
      </Grid>
    );
  }

  const renderComments = () => {
    return (
      <Grid item>
        <ComponentController
          name="commentsBordereaux"
          control={control}
          rules={{ maxLength: maxCommentsLength }}
          render={({ field: { name, onBlur, onChange } }) =>
            <TextField
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              onBlur={onBlur}
              onChange={e => { onFieldChange(name, e.target?.value); onChange(e); }}
              value={fields.commentsBordereaux}
              error={!!errors[name]}
              helperText={<InformationMessage
                fieldName={GeneralDetailsFields.commentsBordereaux}
                fieldErrors={errors.commentsBordereaux}
                maxLength={maxCommentsLength}
                showLengthInfo={true}
                length={fields.commentsBordereaux.length}
              />}
            />
          }
        />
      </Grid>
    );
  }

  return <>
    {renderLookupForAssuredAndPolicyReference()}
    {renderPolicyReferenceNumber()}
    {renderAssured()}
    {renderTotalNumberOfReferences()}
    {renderComments()}
  </>;
}

export default AccountsReceivableSection;