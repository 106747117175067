import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid, makeStyles } from '@material-ui/core';

import { fetchCurrentUser } from 'Api';
import { FileUploadStatus, FileType } from 'Helpers';

import { ErrorPage } from '../navigation';
import { Banner, BannerType, ConfirmationDialog, Footer, Wizard } from "../common";
import { GeneralDetails, LineOfBusiness, Renewals } from "./Steps";
import {
  DocumentTypes, GeneralDetailsFields, getFormattedDate, ModellingPriorities,
  isDualStampEntity, S4000Entity, HidacEntity, singlePolicyStructure, isDeclaration
} from './Helpers';
import { fetchInitialFormData, submitPolicy, getClassesOfBusiness } from './SubmissionWizard.api';

const useStyles = makeStyles({
  root: {
    paddingBottom: "4rem"
  },
  formSection: {
    paddingTop: "0.9375em"
  }
});

const initialGeneralDetailsState = {
  fields: {
    _assuredOrReassured: '',
    _secondAssuredOrReassured: '',
    _masterAssuredOrMasterReassured: '',
    _secondMasterAssuredOrMasterReassured: '',
    businessEntity: '',
    firstMajorClass: {},
    majorClasses: {},
    majorClass: null,
    minorClass: '',
    class: '',
    producingTeam: '',
    yoa: '',
    documentType: '',
    justificationForEpiUpdate: '',
    conditionCode: '',
    conditionCodeProperties: [],
    monthsAndYears: [],
    placingBasis: '',
    placingBasisCode: '',
    broker: null,
    brokerContact: null,
    policyEntryInformation: '',
    assured: null,
    secondAssured: null,
    underwriterEmail: '',
    underwriterFullName: '',
    underwriter: null,
    documentTypesList: [],
    critical: false,
    renewedPolicy: false,
    expiringReferenceSwitch: false,
    isDualStamp: false,
    requiredModellingPriority: '',
    typeOfModellingRisk: '',
    typeOfBordereaux: '',
    sendTaskToOperations: false,
    finalPricingCompleted: false,
    peerReviewNotRequired: false,
    pricingNotRequired: false,
    policyId: null,
    policyReferenceNumber: '',
    secondPolicyId: null,
    secondPolicyReferenceNumber: '',
    masterPolicyReferenceNumber: '',
    masterPolicyId: null,
    masterAssured: '',
    masterReassured: '',
    secondMasterPolicyReferenceNumber: '',
    secondMasterPolicyId: null,
    secondMasterAssured: '',
    secondMasterReassured: '',
    expiringProgrammeReference: '',
    isExpiringPolicyRenewed: false,
    premiumBearing: false,
    eeaRisk: false,
    totalNumberOfPolicyReferences: 1,
    totalNumberOfMonthsRequired:1,
    comments: '',
    commentsBordereaux: '',
    declineImmediately: false,
    operatingTerritory: '',
    territoryId: null,
    inceptionDate: null,
    reassured: '',
    coverHolder: '',
    eplacement: '',
    contractBasis: '',
    period: null
  },
  dropdownsData: {
    documentTypes: [],
    typesOfModellingRisk: [],
    typesOfBordereaux: [],
    modellingPriorities: ModellingPriorities,
    years: [],
    placingBases: [],
    entities: {},
    eclipseEntities: [],
    entitiesList: [],
    entitiesForFilter:[],
    majorClasses: [],
    conditionCodes: [],
    eplacements: [],
    bordereauxPeriods: {},
  },
  modellingMajorClasses: []
};

const initialForceClearDatasourceState = { '': false }

const initialLineOfBusinessState = {
  fields: {
    policyStructure: '',
    totalNumberOfPolicyReferences: 1,
    periodBasis: '',
    periodBasisCode: '',
    obligor: '',
  },
  dropdownsData: {
    periodBases: [],
    entities: {},
    classesOfBusiness: [],
    eclipseEntities: {},
    policyStructures: [],
    declarationTypes: [],
    modellingPriorities: ModellingPriorities
  },
  policies: [],
  modellingMajorClasses: [],
  assuredValidations: []
};

const initialRenewalState = {
  showUserDialogWarning: false,
  fields: [],
  dropdownsData: [],
  originalRenewals: []
};

export const SubmissionWizard = ({ setLoading }) => {
  const [generalDetailsState, setGeneralDetailsState] = useState(initialGeneralDetailsState);
  const [forceClearDatasourceState, setForceClearDatasourceState] = useState(initialForceClearDatasourceState);
  const [lineOfBusinessState, setLineOfBusinessState] = useState(initialLineOfBusinessState);
  const [renewalState, setRenewalState] = useState(initialRenewalState);
  const [businessEntity, setBusinessEntity] = useState({});
  const [currentEntity, setCurrentEntity] = useState({});
  const [currentYoa, setCurrentYoa] = useState({});
  const [files, setFiles] = useState([]);
  const [activeWizardStep, setActiveWizardStep] = useState(0);
  const [bannerState, setBannerState] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [underwriterEmail, setUnderwriterEmail] = useState("");
  const [underwriterFullName, setUnderwriterFullName] = useState("");
  const [taskToOperationsDialogState, setTaskToOperationsDialogState] = useState(false);
  const [noFilesDialogState, setNoFilesDialogState] = useState(false);
  const [masterNotRenewedDialogState, setMasterNotRenewedDialogState] = useState(false);
  const [defaultUnderwriterDialogWarningState, setDefaultUnderwriterDialogWarningState] = useState(false);

  const submissionsLikeDocumentTypes = [
    DocumentTypes.Submissions, DocumentTypes.FirmOrderOnly, DocumentTypes.Quote
  ];

  let validationRef = useRef(null);
  let classes = useStyles();

  useEffect(() => {
    const initializeForm = async () => {
      setLoading(true);

      const response = await fetchInitialFormData();
      if (response.success) {
        await loadUserInfo();
        loadFormData(response.data);
      } else {
        if (response.permissionError) {
          setErrorMessage(response.errorMessage);
        } else {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: response.errorMessage
          });
        }
      }

      setLoading(false);
    }

    initializeForm();
  }, [setLoading]);

  useEffect(() => {
    setLineOfBusinessState(previous => ({
      ...previous,
      fields: { 
        ...initialLineOfBusinessState.fields,
        policyStructure: isDualStampEntity(generalDetailsState.fields.businessEntity ?? '') ? '' : singlePolicyStructure
      },
      policies: []
    }));
  }, [generalDetailsState.fields.documentType, generalDetailsState.fields.businessEntity]);

  const loadFormData = data => {
    let entities = [];

    for (let entityName in data.entities) {
      if (data.entities.hasOwnProperty(entityName)) {
        entities.push(entityName);
      }
    }

    const businessEntities = Object.values(data.entities).map(entity => entity.eclipseEntities).reduce((r, e) => (r.push(...e), r), []);
    
    setGeneralDetailsState(previous => ({
      ...previous,
      dropdownsData: {
        ...previous.dropdownsData,
        typesOfModellingRisk: data.typesOfModellingRisk,
        typesOfBordereaux: data.typesOfBordereaux,
        documentTypes: data.documentTypes,
        placingBases: data.placingBases,
        entitiesList: entities,
        entitiesForFilter:businessEntities,
        entities: data.entities,
        conditionCodes: data.conditionCodes,
        eplacements: data.ePlacements,
        bordereauxPeriods: data.bordereauxPeriods
      },
      modellingMajorClasses: data.modellingMajorClasses
    }));

    setLineOfBusinessState(previous => ({     
      ...previous,
      dropdownsData: {
        ...previous.dropdownsData,
        policyStructures: data.policyStructures,
        periodBases: data.periodBases,
        declarationTypes: data.declarationTypes
      },
      assuredValidations: data.assuredValidations
    }));
  }

  const loadUserInfo = async () => {
    var user = await fetchCurrentUser();
    setUnderwriterEmail(user?.mail);
    setUnderwriterFullName(user?.displayName);
  }

  let generalDetails = useMemo(() =>
    <GeneralDetails
      name="General Details"
      initialState={initialGeneralDetailsState}
      generalDetailsState={generalDetailsState}
      renewalState={renewalState}
      initialRenewalState={initialRenewalState}
      setGeneralDetailsState={setGeneralDetailsState}
      forceClearDatasourceState={forceClearDatasourceState}
      setForceClearDatasourceState={setForceClearDatasourceState}
      setBusinessEntity={setBusinessEntity}
      businessEntity={businessEntity}
      files={files}
      validationRef={validationRef}
      setLineOfBusinessState={setLineOfBusinessState}
      setFiles={setFiles}
      setLoading={setLoading}
      setBannerState={setBannerState}
      setRenewalState={setRenewalState}
    />,
    [generalDetailsState, renewalState, forceClearDatasourceState, files, setRenewalState, setLoading, businessEntity]
  );

  let lineOfBusiness = useMemo(() =>
    <LineOfBusiness
      name="Line Of Business"
      initialState={initialLineOfBusinessState}
      lineOfBusinessState={lineOfBusinessState}
      businessEntity={businessEntity}
      currentEntity={currentEntity}
      setCurrentEntity={setCurrentEntity}
      setCurrentYoa={setCurrentYoa}
      currentYoa={currentYoa }
      validationRef={validationRef}
      setLineOfBusinessState={setLineOfBusinessState}
      setLoading={setLoading}
      setBannerState={setBannerState}
      getClassesOfBusiness={getClassesOfBusiness}
      generalDetailsState={generalDetailsState}
    />,
    [lineOfBusinessState, businessEntity, currentEntity, setLoading, generalDetailsState]
  );

  let renewals = useMemo(() =>
    <Renewals
      name="Renewals"
      renewalState={renewalState}
      generalDetailsState={generalDetailsState}
      validationRef={validationRef}
      setRenewalState={setRenewalState}
      setBannerState={setBannerState}
      setLoading={setLoading}
      declarationTypes={lineOfBusinessState.dropdownsData.declarationTypes}
    />,
    [renewalState, setRenewalState, generalDetailsState, lineOfBusinessState, setLoading]
  );

  const getWizardSteps = useCallback(() => {
    if (generalDetailsState.fields.renewedPolicy && submissionsLikeDocumentTypes.includes(generalDetailsState.fields.documentType)) {
      return [generalDetails, renewals];
    }

    if (submissionsLikeDocumentTypes.includes(generalDetailsState.fields.documentType)) {
      return [generalDetails, lineOfBusiness];
    } else {
      return [generalDetails];
    }
  }, [generalDetails, generalDetailsState.fields.documentType, generalDetailsState.fields.renewedPolicy,
    renewalState.showUserDialogWarning, lineOfBusiness, renewals, submissionsLikeDocumentTypes]);

  const clearForm = useCallback(() => {
    setFiles([]);
    setActiveWizardStep(0);

    setLineOfBusinessState(previous => ({
      ...initialLineOfBusinessState,
      dropdownsData: previous.dropdownsData,
      assuredValidations: previous.assuredValidations
    }));

    setGeneralDetailsState(previous => ({
      fields: { ...initialGeneralDetailsState.fields },
      dropdownsData: { ...previous.dropdownsData, years: [] },
      modellingMajorClasses: { ...previous.modellingMajorClasses }
    }));

    setRenewalState({ ...initialRenewalState });
    setForceClearDatasourceState({ ...initialForceClearDatasourceState });
  }, []);

  const processRenewPolicies = useCallback(() => {
    let policiesData = renewalState.fields.filter(x => x.renewed === true);
    if (!policiesData.some(p => p.mainLayer)) {
      policiesData[0].mainLayer = true;
    }

    let mainPolicy = policiesData[0];
    let originalRenewal = renewalState.originalRenewals.find(r => r.id === mainPolicy.policyId);
    let generalDetails = {
      ...generalDetailsState,
      fields: {
        ...generalDetailsState.fields,
        underwriter: {
          underwriter: mainPolicy.underwriterName,
          underwriterAbbreviation: mainPolicy.uw
        },
        assured: {
          assured: originalRenewal.assured,
          assuredId: originalRenewal.assuredId
        },
        businessEntity: mainPolicy.entityCode,
        classType: mainPolicy.classType,
        classTypeCode: mainPolicy.classTypeCode,
        majorClass: {
          majorClass: mainPolicy.majorClass,
          majorClassCode: mainPolicy.majorClassCode
        },
        minorClass: mainPolicy.minorClass,
        class: mainPolicy.class,
        yoa: mainPolicy.year,
        inceptionDate: mainPolicy.inceptionDate,
        placingBasis: originalRenewal.placingBasis,
        placingBasisCode: originalRenewal.detail.placingType,
        contractBasis: originalRenewal.contractCertainty.contractBasis,
        reassured: originalRenewal.reassuredOrganisation,
        coverholder: originalRenewal.coverholder,
        operatingTerritory: mainPolicy.territory,
        broker: {
          lloydsBrokerID: mainPolicy.lloydsBrokerID,
          brokerId: mainPolicy.orgId,
          broker: mainPolicy.broker,
          brokerCode: mainPolicy.brokerNo,
          brokerPseud: mainPolicy.brokerPseud
        },
        brokerContact: {
          brokerContactId: mainPolicy.brokerContactId,
          brokerContact: mainPolicy.brokerContact
        },
        expiringPolicyReferenceNumber: originalRenewal.detail.policyRef,
        masterPolicyReferenceNumber: mainPolicy.masterPolicyReferenceNumber,
        masterAssured: mainPolicy.masterAssured,
        masterReassured: mainPolicy.masterReassured
      }
    };

    let lineOfBusiness = {
      ...lineOfBusinessState,
      fields: {
        ...lineOfBusinessState.fields,
        policyStructure: mainPolicy.policyStructure,
        obligor: originalRenewal.obligor
      }
    };

    policiesData = policiesData.map(x => ({
      ...x,
      inceptionDate: getFormattedDate(new Date(x.inceptionDate))
    }));

    return { generalDetails, lineOfBusiness, policiesData };
  }, [renewalState, generalDetailsState, lineOfBusinessState]);

  const processDeclaration = (generalDetails, policiesData) => {
    if (isDualStampEntity(generalDetails.fields.businessEntity)) {
      let s4000 = policiesData.find(p => p.entity === S4000Entity.Value);
      let hidac = policiesData.find(p => p.entity === HidacEntity.Value);

      generalDetails.fields.assured = {
        assured: s4000.declarationAssured,
        assuredId: s4000.declarationAssuredId,
        effectiveFromDate: s4000.effectiveFromDate,
        effectiveToDate: s4000.effectiveToDate
      };

      generalDetails.fields.secondAssured = {
        assured: hidac.declarationAssured,
        assuredId: hidac.declarationAssuredId,
        effectiveFromDate: hidac.effectiveFromDate,
        effectiveToDate: hidac.effectiveToDate
      };

      generalDetails.fields.declarationType = s4000.declarationType;
    } else {
      generalDetails.fields.assured = {
        assured: policiesData[0].declarationAssured,
        assuredId: policiesData[0].declarationAssuredId,
        effectiveFromDate: policiesData[0].effectiveFromDate,
        effectiveToDate: policiesData[0].effectiveToDate
      };

      generalDetails.fields.declarationType = policiesData[0].declarationType;
    }

    return generalDetails;
  }

  const submitForm = useCallback(async () => {
    let generalDetails;
    let lineOfBusiness;
    let policiesData;
    let totalNumberOfPolicyReferences;

    let attachedFiles = files.map(f => {
      if (f.type === FileType.message) {
        return {
          name: f.file.name,
          path: f.upload.path,
          body: f.body,
          title: f.title,
          sender: f.sender,
          attachments: f.attachments
        };
      }

      return { name: f.file.name, path: f.upload.path };
    });

    let renew = generalDetailsState.fields.renewedPolicy;
    if (renew && submissionsLikeDocumentTypes.includes(generalDetailsState.fields.documentType)) {
      let renewData = processRenewPolicies();
      generalDetails = renewData.generalDetails;
      lineOfBusiness = renewData.lineOfBusiness;
      policiesData = renewData.policiesData;
      totalNumberOfPolicyReferences = policiesData.length;
    } else {
      generalDetails = generalDetailsState;
      lineOfBusiness = lineOfBusinessState;
      policiesData = lineOfBusiness.policies;

      /// revert https://dev.azure.com/Hamilton-development/gupe/_workitems/edit/94338
      if (generalDetails.fields.renewedToRef) {
       generalDetails.fields.renewedPolicy = generalDetails.fields.renewedToRef;
      }

      if (submissionsLikeDocumentTypes.includes(generalDetails.fields.documentType)) {
        totalNumberOfPolicyReferences = lineOfBusiness.fields.totalNumberOfPolicyReferences;

        if (isDeclaration(generalDetails.fields.placingBasisCode)) {
          generalDetails = processDeclaration(generalDetails, policiesData);
        }
      } else {
        totalNumberOfPolicyReferences = generalDetails.fields.totalNumberOfPolicyReferences;
      }
    }

    let payload = {
      ...generalDetails.fields,
      ...lineOfBusiness.fields,
      underwriterEmail,
      underwriterFullName,
      inceptionDate: getFormattedDate(generalDetails.fields.inceptionDate),
      policies: policiesData,
      totalNumberOfPolicyReferences
    };

    let response = await submitPolicy(payload, attachedFiles);
    if (response.success) {
      clearForm();

      setBannerState({
        type: BannerType.success,
        message: "Submission is successfully started.",
        show: true
      });
    } else {
      setBannerState({
        type: BannerType.error,
        message: response.errorMessage,
        show: true,
        expose: true
      });
    }

    setLoading(false);
  }, [files, generalDetailsState, underwriterEmail, underwriterFullName, setLoading, processRenewPolicies, 
    lineOfBusinessState, submissionsLikeDocumentTypes, clearForm]);

  const onSubmit = useCallback(async () => {
    setLoading(true);

    if (files.length === 0 || files.every(file => file.upload.status === FileUploadStatus.success)) {
      await submitForm();
    }
    else if (files.some(file =>
      file.upload.status !== FileUploadStatus.success ||
      file.upload.status !== FileUploadStatus.inProgress)) {
      setLoading(false);
      setBannerState({
        show: true,
        type: BannerType.error,
        message: "Some of the dropped files haven't been uploaded. Cannot submit."
      });
    }
    else {
      const uploadPromises = files
        .filter(file => file.upload.status === FileUploadStatus.inProgress)
        .map(file => file.upload.promise);

      Promise.all(uploadPromises)
        .then(
          async () => { // on success
            await submitForm();
          },
          () => { // on error
            setLoading(false);
          });
    }
  }, [files, setLoading, setBannerState, submitForm]);

  const onWizardSubmit = useCallback(async () => {
    if (generalDetailsState.fields.renewedPolicy && renewalState.showUserDialogWarning) {
      setDefaultUnderwriterDialogWarningState(true);
    } else if(generalDetailsState.fields.sendTaskToOperations) {
      setTaskToOperationsDialogState(true);
    } else if (files.length === 0 && getWizardSteps().length === 1) {
      setNoFilesDialogState(true);
    } else if (generalDetailsState.fields.renewedPolicy && renewalState.fields.find(x => x.isMasterNotRenewed === true)) {
      setMasterNotRenewedDialogState(true);
    } else {
      onSubmit();
    }
  }, [generalDetailsState.fields.sendTaskToOperations, files.length, renewalState,
    renewalState.showUserDialogWarning, getWizardSteps, onSubmit]);

  const onWizardNext = useCallback(() => {
    if (files.length === 0) {
      setNoFilesDialogState(true);
    } else {
      setActiveWizardStep(previousStep => previousStep + 1);
    }
  }, [files.length]);

  const handleNoFilesDialogConfirm = async () => {
    if (getWizardSteps().length > 1) {
      setActiveWizardStep(previousStep => previousStep + 1);
    } else {
      await onSubmit();
    }
  }

  const renderDefaultUserDialog = () => {
    return (
      <ConfirmationDialog
        isOpened={defaultUnderwriterDialogWarningState}
        content="Please ensure the UW has been changed to ''XX - Default User'' before submitting the renewal"
        onConfirm={onSubmit}
        config={{
          cancelButtonLabel: "Ok",
          warning: true
        }}        
        setIsOpened={setDefaultUnderwriterDialogWarningState}
      />
    );
  }

  const renderMasterNotRenewedDialog = () => {
    return (
      <ConfirmationDialog
        isOpened={masterNotRenewedDialogState}
        content="Master Policy has not been renewed yet. Declaration will be linked to current Master. Would you like to continue?"
        onConfirm={onSubmit}
        config={{
          cancelButtonLabel: "No",
          confirmButtonLabel: "Yes"
        }}
        setIsOpened={setMasterNotRenewedDialogState}
      />
    );
  }

  const renderNoFilesDialog = () => {
    return (
      <ConfirmationDialog
        isOpened={noFilesDialogState}
        content="Confirm that the form does not have any attachments"
        onConfirm={handleNoFilesDialogConfirm}
        setIsOpened={setNoFilesDialogState}
      />
    );
  }

  const renderSendTaskToOperationsDialog = () => {
    let dialogContent =
      `'${GeneralDetailsFields.sendTaskToOperations}' has been selected, therefore reference(s) will not be automatically created`;

    return (
      <ConfirmationDialog
        isOpened={taskToOperationsDialogState}
        content={dialogContent}
        onConfirm={onSubmit}
        config={{
          cancelButtonLabel: "Decline",
          confirmButtonLabel: "Accept"
        }}
        setIsOpened={setTaskToOperationsDialogState}
      />
    );
  }

  const renderWizard = () => {
    if (errorMessage.length > 0) {
      return <ErrorPage message={errorMessage} />
    }

    return (
      <div className={classes.root}>
        <Banner
          type={bannerState.type}
          message={bannerState.message}
          show={bannerState.show}
          showBanner={show => setBannerState({
            ...bannerState,
            show: show
          })}
        />
        <Grid container className={classes.formSection} direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
          <Wizard steps={getWizardSteps()} activeStep={activeWizardStep} setActiveStep={setActiveWizardStep} onNext={onWizardNext} onSubmit={onWizardSubmit} />
        </Grid>
        <Footer />
      </div>
    );
  }

  return <>
    {renderWizard()}
    {renderSendTaskToOperationsDialog()}
    {renderDefaultUserDialog()}
    {renderNoFilesDialog()}
    {renderMasterNotRenewedDialog()}
  </>;
}