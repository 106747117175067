import React, { useState } from 'react';
import { Route } from 'react-router';
import { Routes, BrowserRouter } from 'react-router-dom';
import BlockUi from '@availity/block-ui';
import { AppContext } from './AppContext';
import { withAuth } from './Authentication';
import {
  Form,
  RpaDropzones,
  BrokerQuote,
  BrokerLetter,
  HealthCheck,
  ErrorPage,
  EditableFields,
  MiamiClone,
  SubmissionWizard,
  AITimeflow,
} from 'components';
import DefaultLayout from './layouts/DefaultLayout';
import { Area } from './Helpers';
import './App.css';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const RootApp = (props) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <BrowserRouter baseUrl={baseUrl}>
      <BlockUi blocking={isLoading}>
        <Routes>
          <Route
            path="/ai"
            element={
              <AITimeflow setLoading={setLoading}>
                <Form setLoading={setLoading} />
              </AITimeflow>
            }
          >
            <Route path=":formType" element={null}>
              <Route path=":tabId" element={null}>
                <Route path=":itemId" element={null} />
              </Route>
            </Route>
          </Route>
          <Route
            path="/healthCheck"
            element={
              <DefaultLayout>
                <HealthCheck />
              </DefaultLayout>
            }
          />
          <Route
            path="/rpa-dropzone"
            element={
              <AppContext.Provider value={{ area: Area.HamiltonRe }}>
                <DefaultLayout>
                  <RpaDropzones setLoading={setLoading} />
                </DefaultLayout>
              </AppContext.Provider>
            }
          />
          <Route
            path="/broker-quote"
            element={
              <AppContext.Provider value={{ area: Area.HamiltonSelect }}>
                <DefaultLayout>
                  <BrokerQuote setLoading={setLoading} />
                </DefaultLayout>
              </AppContext.Provider>
            }
          />
          <Route
            path="/submission-form"
            element={
              <AppContext.Provider value={{ area: Area.HamiltonInternational }}>
                <DefaultLayout>
                  <SubmissionWizard setLoading={setLoading} />
                </DefaultLayout>
              </AppContext.Provider>
            }
          />
          <Route
            path="/broker-letter"
            element={
              <DefaultLayout>
                <BrokerLetter setLoading={setLoading} />
              </DefaultLayout>
            }
          />
          <Route
            path="/editable-fields"
            element={
              <DefaultLayout>
                <EditableFields setLoading={setLoading} />
              </DefaultLayout>
            }
          />
          <Route
            path="/miami-clone"
            element={
              <DefaultLayout>
                <MiamiClone setLoading={setLoading} />
              </DefaultLayout>
            }
          />
          <Route
            path="/"
            element={
              <DefaultLayout>
                <Form setLoading={setLoading} />
              </DefaultLayout>
            }
          />
          <Route
            path="*"
            element={
              <DefaultLayout>
                <ErrorPage message="404 - Page not found!" />
              </DefaultLayout>
            }
          />
        </Routes>
      </BlockUi>
    </BrowserRouter>
  );
};

export const App = withAuth(RootApp);
