import React, { useCallback } from 'react';

import { OrganisationTypes, PlacingBases, DeclarationPlacingType } from 'components/SubmissionWizard/Helpers';
import { PolicyReferenceControl } from './PolicyReferenceControl';
import { getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';

export const AssuredAndPolicyReferenceControl = ({
  fields,
  control,
  errors,
  lookupType,
  additionalRequestData,
  forceClearDatasourceState,
  setBannerState,
  setLoading,
  setFormState,
  setValidationValue,
  includePlacingBasis,
  forceEnabled,
  onChange,
  abortController,
  setAbortController
}) => {
  const getAssured = useCallback((previousState, assuredRow) => {

    let assuredData = {
      _assuredOrReassured: assuredRow?.assured ?? '',
      policyId: assuredRow?.policyId,
      policyReferenceNumber: assuredRow?.policyReferenceNumber ?? '',
      classTypeCode: assuredRow?.classTypeCode ?? '',
      yoa: assuredRow?.yearOfAccount,
      businessEntity: (previousState.fields.isDualStamp || assuredRow === null) ? previousState.fields.businessEntity : assuredRow?.businessEntity,
      umr: assuredRow?.umr,
      renewedToRef: assuredRow?.renewed,
      renewedPolicyS4000: previousState.fields.isDualStamp ? assuredRow?.renewed : null
    };

    if (assuredRow?.assuredType.toLowerCase() === OrganisationTypes.Reassured) {
      assuredData.reassured = assuredRow?.assured;
      assuredData.assured = null;
    } else {
      assuredData.reassured = "";
      assuredData.declarationReference = assuredRow?.declarationReference ?? '';
      assuredData.assured = {
        assured: assuredRow?.assured ?? '',
        assuredId: assuredRow?.assuredId
      };
    }

    if (includePlacingBasis) {
      let placingBasis = { placingBasis: assuredRow?.placingBasis, placingBasisCode: assuredRow?.placingType }
      assuredData = { ...assuredData, ...placingBasis };
    }

    return assuredData;
  }, [includePlacingBasis]);

  const setMasterAssured = useCallback(async assuredRow => {
    let masterPolicyData = {};

    if (assuredRow?.placingType === DeclarationPlacingType) {
      setLoading(true);
      let policy = await getPolicy(assuredRow.policyId);
      let masterPolicy = await getPolicy(policy.data.detail.parentPolicyId);
      setLoading(false);

      let assureds = masterPolicy.data.assuredOrganisations;
      let reassureds = masterPolicy.data.reassured;
      masterPolicyData.masterAssured = !!assureds && assureds[0]?.orgName;
      masterPolicyData.masterReassured = !!reassureds && reassureds[0]?.orgName;
      masterPolicyData.masterPolicyReferenceNumber = masterPolicy.data.detail.policyRef;
    } else {
      masterPolicyData.masterAssured = "";
      masterPolicyData.masterReassured = "";
      masterPolicyData.masterPolicyReferenceNumber = "";
    }

    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, ...masterPolicyData }
    }));
  }, [setFormState])

  const requestData = { ...additionalRequestData, majorClassCodes: onChange ? [] : fields.majorClass?.majorClassCodes };

  return (<PolicyReferenceControl
    controlName='assured'
    getValue={fieldData => fieldData._assuredOrReassured}
    fields={fields}
    control={control}
    errors={errors}
    lookupType={lookupType}
    requestData={requestData}
    forceClearDatasourceState={forceClearDatasourceState}
    setBannerState={setBannerState}
    setLoading={setLoading}
    setFormState={setFormState}
    setValidationValue={setValidationValue}
    getAssuredData={getAssured}
    setMasterAssuredData={setMasterAssured}
    disabled={forceEnabled ? !forceEnabled : !fields.majorClass}
    onAssuredPolicyChanged={onChange}
    abortController={abortController}
    setAbortController={setAbortController }
  />);
}
