import React, { useState, useEffect, useCallback, useContext } from 'react';
import clsx from 'clsx';

import { makeStyles, TextField, CircularProgress, Grid, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import debounce from 'lodash/debounce';

import { fetchPickerRows } from 'Api';
import { AppContext } from 'AppContext';
import { BannerType } from '../Banner';

const defaultConfig = {
  loadingTime: 1000,
  rowsLimit: 50,
  pickerViewClass: "",
  autoSearch: false
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "13px",
    '&:hover': {
      backgroundColor: "#1fb6ad",
      color: "#ffffff"
    }
  },
  pickerRow: {
    borderTop: "1px solid rgba(0, 0, 0, 0)",
    textAlign: "center",
    overflowWrap: "break-word"
  },
  pickerNewRow: {
    color: "#1fb6ad",
    fontWeight: "bold",
    fontStyle: "italic",
    paddingRight: "0.5rem",
    paddingLeft: "0.5rem"
  },
  pickerViewHeader: {
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    paddingLeft: "1rem",
    paddingRight: "2.0625rem"
  }
}));

export const PickerComponent = ({
  label,
  fieldName,
  value,
  disabled,
  onChange,
  onBlur,
  datasource,
  abortController,
  error,
  errorText,
  requestData,
  config,
  forceClearDatasource,
  Popup,
  onPopupSubmit,
  submitData,
  OptionsHeader,
  OptionView,
  setBannerState,
  setFormState,
  setValidationValue,
  setLoading,
  setAbortController,
  required
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [popupState, setPopupState] = useState({ show: false, newValue: "" });
  const [pickerClassesState, setPickerClassesState] = useState({ popper: defaultConfig.pickerViewClass });

  const appContext = useContext(AppContext);
  const classes = useStyles();

  useEffect(() => {
    if(value === null)
    {
      setRows([]);
    }
  }, [value]);

  useEffect(() => {
    if (forceClearDatasource && forceClearDatasource[fieldName]) {
      setRows([]);
    }
  }, [forceClearDatasource])

  const resetPickerClassState = useCallback(() => {
    if (rows?.length === 0) {
      setPickerClassesState(classState => ({
        ...classState,
        popper: defaultConfig.pickerViewClass
      }));
    }
  }, [rows, setPickerClassesState]);

  const loadRows = useCallback(async (requestPayload, filter) => {
    
    if(abortController == null)
    {
      abortController = new AbortController(); 
    }
    
    if(abortController.signal.aborted)
    {
      abortController = new AbortController();
      setAbortController(abortController)
    }

    let request = { filter, ...requestPayload };
    let response = await fetchPickerRows(datasource, request, appContext.area, { signal: abortController.signal });

    if (response.canceled) {
      setDataLoading(false);
      setAbortController(new AbortController());
      return;
    }

    if (response.success) {
      setRows(response.data);

      if (config?.pickerViewClass) {
        setPickerClassesState(classState => ({
          ...classState,
          popper: config.pickerViewClass
        }));
      }
    } else {
      setBannerState({
        show: true,
        type: BannerType.error,
        message: response.errorMessage
      });
    }

    setDataLoading(false);
  }, [abortController, datasource, appContext.area, setPickerClassesState, setBannerState, setAbortController]);

  const getRowsQuery = useCallback(debounce(async filter => {
    await loadRows(requestData, filter);
  }, defaultConfig.loadingTime), [requestData]);

  const handleInputFocus = useCallback(async () => {
    if (forceClearDatasource?.hasOwnProperty(fieldName)) {
      delete forceClearDatasource[fieldName];
      setRows([]);
    }

    if (config?.autoSearch ?? defaultConfig.autoSearch) {
      resetPickerClassState();
      setDataLoading(true);
      await getRowsQuery("");
    }
  }, [resetPickerClassState, setDataLoading, getRowsQuery, forceClearDatasource]);

  const handleTextChange = useCallback(async event => {
    let text = event.target?.value;

    if (text) {
      if(abortController != null)
      {
        abortController.abort();
      }

      resetPickerClassState();
      await getRowsQuery(text);
      setDataLoading(true);
    }
  }, [abortController, resetPickerClassState, setDataLoading, getRowsQuery]);

  const handleChange = (_, selectedValue) => {
    if(abortController != null)
    {
      abortController.abort();
    }
    
    if(selectedValue === null) {
      setRows([]);
    }
    
    if (typeof selectedValue === 'string') {
      setPopupState({
        newValue: selectedValue,
        show: true
      });
    } else if (selectedValue && selectedValue.inputValue) {
      // Create a new value from the user input
      setPopupState({
        newValue: selectedValue.inputValue,
        show: true
      });
    } else {
      onChange(selectedValue);
    }
  }

  const filterOptions = (options, params) => {
    let filteredRows = options;

    let limit = config?.rowsLimit ?? defaultConfig.rowsLimit;
    filteredRows.splice(limit);

    // Suggest the creation of a new value
    if (Popup && params.inputValue !== '') {
      filteredRows.unshift({
        inputValue: params.inputValue,
        [fieldName]: `Add "${params.inputValue}" ${fieldName}`
      });
    }

    return filteredRows;
  }

  const getOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }

    // Regular option
    return option[fieldName];
  }

  const renderInputElement = params => (
    <TextField
      {...params}
      required={required}
      label={label}
      onFocus={handleInputFocus}
      onChange={handleTextChange}
      error={error}
      helperText={errorText}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {dataLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );

  const PaperComponent = ({ children }) => {
    if (OptionsHeader && rows.length > 0) {
      return (
        <Paper>
          <OptionsHeader className={classes.pickerViewHeader} />
          {children}
        </Paper>
      );
    }

    return <Paper>{children}</Paper>;
  }

  const renderOption = option => {
    if (OptionView && !option.inputValue) {
      return <OptionView option={option} className={clsx(classes.root, classes.pickerRow)} />;
    }

    let className = option.inputValue ? classes.pickerNewRow : classes.pickerRow;

    return (
      <Grid container direction="row" spacing={1} className={clsx(classes.root, className)}>
        <Grid item xs={12}><div>{option[fieldName]}</div></Grid>
      </Grid>
    );
  }

  const renderPopup = () => {
    return popupState.show &&
      <Popup
        popupState={popupState}
        onPopupSubmit={onPopupSubmit}
        submitData={submitData}
        requestData={requestData}
        setPopupState={setPopupState}
        setFormState={setFormState}
        setValidationValue={setValidationValue}
        setBannerState={setBannerState}
        setLoading={setLoading}
      />;
  }

  return <>
    <Autocomplete
      id={fieldName}
      name={fieldName}
      value={value}
      classes={pickerClassesState}
      PaperComponent={PaperComponent}
      disabled={disabled}
      filterOptions={filterOptions}
      loading={dataLoading}
      onChange={handleChange}
      onBlur={onBlur}
      clearOnBlur
      clearOnEscape
      freeSolo
      autoHighlight
      handleHomeEndKeys
      options={rows}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={renderInputElement}
    />
    {renderPopup()}
  </>;
}