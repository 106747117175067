import React, { useCallback, useEffect, useState } from 'react';

import { FormControl, Grid, TextField } from '@material-ui/core';

import { Datasource } from 'Api';
import { validateDate } from 'Helpers';

import {
  BannerType, CheckboxElement, DatePickerElement, Dropdown,
  FormControlWrapper, PickerComponent, SwitchComponent, ComponentController
} from 'components/common';
import { ConditionCode, MasterPolicyReferenceControl, SecondMasterPolicyReferenceControl } from './common';
import {
  GeneralDetailsFields, PlacingBases, ReassuredPlacingBases,
  isDualStampEntity, S4000Entity, validatePlacingBasis, DocumentTypes, setZeroTime, toLocalDateTime, singlePolicyStructure, ModellingPriorities, isDeclaration,
  isPolicyRenewedWithCustomLink
} from 'components/SubmissionWizard/Helpers';
import { getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';
import { AssuredPopup, ReassuredPopup, BrokerContactPopup } from 'components/SubmissionWizard/Popups';

import '../GeneralDetails.css';

const SubmissionSection = ({
  fields,
  initialFormState,
  renewalState,
  initialRenewalState,
  dropdownsData,
  businessEntity,
  control,
  errors,
  forceClearDatasourceState,
  setFormState,
  setRenewalState,
  setValidationValue,
  trigger,
  setLoading,
  setBannerState,
  setBusinessEntity,
  setGeneralDetailsState,
  validationRef,
  resetValidationState,
  resetFieldValidationState,
  setLineOfBusinessState,
  setForceClearDatasourceState
}) => {
  const [abortController, setAbortController] = useState(new AbortController());

  useEffect(() => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields }
    }));
  }, [setFormState]);

   useEffect(() => {
     if (fields.assured?.assured) {
       trigger('assured');
     }
   }, [fields.inceptionDate]);

  useEffect(() => {
    trigger('inceptionDate');
  }, [fields.yoa, fields.inceptionDate]);

  useEffect(() => {

    let checkIfPolicyRenewed = async () => {
      if (fields.policyId && renewalState.fields.length === 0 && renewalState.dropdownsData.length === 0) {
        setLoading(true);
        let response = await getPolicy(fields.policyId);
        setLoading(false);

        if (response.success) {          
          let policy = response.data;
          let isPolicyRenewed = !!policy?.detail?.renewedToId || isPolicyRenewedWithCustomLink(policy);

          setFormState(previous => ({
            ...previous,
            fields: {
              ...previous.fields,
              isExpiringPolicyRenewed: isPolicyRenewed,
              expiringProgrammeReference: policy?.detail.programRef
            }
          }));

          setValidationValue("expiringPolicyReferenceNumber", fields.expiringPolicyReferenceNumber, { shouldValidate: true });
        } else {
          setBannerState({
            show: true,
            type: BannerType.error,
            message: response.errorMessage
          });
        }
      }
    }

    checkIfPolicyRenewed();
  }, [
    fields.policyId, fields.expiringPolicyReferenceNumber, renewalState,
    setLoading, setBannerState, setFormState, setValidationValue
  ]);

  const onFieldChange = useCallback((name, value) => {
    setFormState(previous => ({
      ...previous,
      fields: { ...previous.fields, [name]: value === "" && name === "inceptionDate" ? null : value }
    }));
  }, [setFormState]);

  const onRenewedPolicyChecked = useCallback(checked => {
    resetFieldValidationState("policyReferenceNumber");
    resetFieldValidationState("expiringProgrammeReference");

    setFormState(previous => {
      let newDropdownsDataState = {
        ...previous.dropdownsData,
        years: [],
        eclipseEntities: []
      };

      let newFieldsState = {
        ...initialFormState.fields,
        critical: previous.fields.critical,
        policyEntryInformation: previous.fields.policyEntryInformation,
        documentType: previous.fields.documentType,
        renewedPolicy: checked
      };

      resetValidationState({ ...newFieldsState });
      return { ...previous, fields: { ...newFieldsState }, dropdownsData: { ...newDropdownsDataState } };
    });

    setRenewalState(previous => ({
      ...previous,
      showUserDialogWarning: false,
      fields: [],
      dropdownsData: [],
      originalRenewals: []
    }));

  }, [initialFormState, setFormState, setRenewalState, resetValidationState, resetFieldValidationState]);

  const onUnderwriterChange = useCallback(async underwriterData => {
    let underwriter = underwriterData ?
      {
        underwriter: underwriterData.underwriter,
        underwriterAbbreviation: underwriterData.underwriterAbbreviation
      } : {};
    let yoaList = underwriterData ? underwriterData.yoaList : [];
    let eclipseEntities = underwriterData ? underwriterData.entitiesList : [];
    
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        underwriter: underwriter ?? '',
        yoa: '',
        businessEntity: '',
        broker: null,
        brokerContact: null,
        assured: null,
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: ''
      },
      dropdownsData: {
        ...previous.dropdownsData,
        years: yoaList,
        eclipseEntities: eclipseEntities
      }
    }));

    setValidationValue("yoa", "");
    setValidationValue("businessEntity", "");
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true });
  }, [setFormState, setValidationValue, setForceClearDatasourceState]);

  const onYOAChange = useCallback(yoa => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        yoa: yoa ?? '',
        businessEntity: '',
        broker: null,
        brokerContact: null,
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: ''
      }
    }));

    setValidationValue("businessEntity", "");
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true, "_masterAssuredOrMasterReassured": true, "_secondMasterAssuredOrMasterReassured": true });
  }, [setFormState, setValidationValue, setForceClearDatasourceState]);

  const onEntityChange = useCallback(event => {
    let businessEntity = dropdownsData.eclipseEntities.find(x => x.entityDisplayName === event.target?.value);

    setBusinessEntity(businessEntity);

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        businessEntity: businessEntity.entityCode,
        isDualStamp: businessEntity.isDualStampEntity,
        broker: null,
        brokerContact: null,
        masterPolicyReferenceNumber: '',
        masterPolicyId: null,
        masterAssured: '',
        masterReassured: '',
        _masterAssuredOrMasterReassured: '',
        _secondMasterAssuredOrMasterReassured: '',
        secondMasterAssured: '',
        secondMasterReassured: '',
        secondMasterPolicyReferenceNumber: '',
        secondMasterPolicyId: null,
        assured: null,
        secondPlacingBasis: businessEntity.isDualStampEntity ? previous.fields.placingBasis : null,
        secondPlacingBasisCode: businessEntity.isDualStampEntity ? previous.fields.placingBasisCode : null,
        secondReassured: businessEntity.isDualStampEntity ? previous.fields.secondReassured : null,
      }
    }));

    setValidationValue("broker", null);
    setValidationValue("brokerContact", null);
    setValidationValue("assured", null);
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setValidationValue("_secondMasterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true, "_masterAssuredOrMasterReassured": true, "_secondMasterAssuredOrMasterReassured": true, "broker": true });
    abortController.abort();
  }, [abortController, dropdownsData.eclipseEntities, setBusinessEntity, setFormState, setValidationValue, setForceClearDatasourceState]);

  const clearLineOfBusinessState = (placingBasis) => {

    const initialLineOfBusinessState = {
      fields: {
        policyStructure: '',
        totalNumberOfPolicyReferences: 1,
        periodBasis: '',
        periodBasisCode: '',
        obligor: '',
      }
    };
   
    setLineOfBusinessState(previous => ({
      ...previous,
      fields: { 
        ...initialLineOfBusinessState.fields,
        policyStructure: previous.fields.policyStructure === '' ? (isDeclaration(placingBasis) ? previous.fields.policyStructure : singlePolicyStructure) : (isDeclaration(placingBasis) ? '' : previous.fields.policyStructure),
      },
      policies: []
    }));
  };

  const onPlacingBasisChange = useCallback(event => {
    resetFieldValidationState("assured");

    let placingBasisName = event.target?.value;
    let placingBasis = dropdownsData.placingBases.find(p => p.name === placingBasisName);
    let contractBasis = placingBasis?.contractBasis;
    let placingBasisCode = placingBasis?.code;
    let prevPlacingBasisCode = fields.placingBasisCode;
    
    if (prevPlacingBasisCode === PlacingBases.Declaration || placingBasis?.code === PlacingBases.Declaration) {
      clearLineOfBusinessState(placingBasisCode);
    }

    setGeneralDetailsState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        placingBasis: placingBasisName,
        placingBasisCode : placingBasisCode
      }
    }));

    setFormState(previous => {
      let broker = previous.fields.broker;
      let brokerContact = previous.fields.brokerContact;
      let assured = null;
      let reassured = null;

      if (placingBasisCode === PlacingBases.Declaration) {
        broker = null;
        brokerContact = null;
      }

      return {
        ...previous,
        fields: {
          ...previous.fields,
          placingBasis: placingBasisName,
          placingBasisCode: placingBasisCode,
          secondPlacingBasis: previous.fields.isDualStamp ? placingBasisName : null,
          secondPlacingBasisCode: previous.fields.isDualStamp ? placingBasisCode : null,
          secondReassured: previous.fields.isDualStamp ? reassured : null,
          broker,
          brokerContact,
          assured,
          reassured,
          decalarationType: null,
          contractBasis,
          masterPolicyReferenceNumber: '',
          masterPolicyId: null,
          masterAssured: '',
          masterReassured: '',
          _masterAssuredOrMasterReassured: '',
          _secondMasterAssuredOrMasterReassured: '',
          secondMasterAssured: '',
          secondMasterReassured: '',
          secondMasterPolicyReferenceNumber: '',
          secondMasterPolicyId: null,
          coverHolder: null,
          umr:''
        }
      };
    });

    if (placingBasisCode === PlacingBases.Declaration) {
      setValidationValue("broker", null);
      setValidationValue("brokerContact", null);
      resetFieldValidationState("decalartionType");
      setForceClearDatasourceState({ "broker": true, "brokerContact": true });
    }

    if ([PlacingBases.Binder, PlacingBases.BinderNonProportionalRI, PlacingBases.BinderProportionalRI, PlacingBases.MasterBinder].includes(placingBasisCode)) {
      setValidationValue("coverHolder", "");
      setForceClearDatasourceState({ "coverHolder": true });
    }

    if (ReassuredPlacingBases.includes(placingBasisCode)) {
      setValidationValue("reassured", "");
      setForceClearDatasourceState({ "reassured": true });
    }

    setValidationValue("assured", null);
    setValidationValue("_masterAssuredOrMasterReassured", "");
    setValidationValue("_secondMasterAssuredOrMasterReassured", "");
    setForceClearDatasourceState({ "assured": true, "_masterAssuredOrMasterReassured": true, "_secondMasterAssuredOrMasterReassured": true });
  }, [dropdownsData, fields, setFormState, resetFieldValidationState, setValidationValue, setForceClearDatasourceState, setGeneralDetailsState]);

  const onBrokerChange = useCallback(broker => {
    setValidationValue("brokerContact", null);
    setForceClearDatasourceState({ "brokerContact": true });
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        broker: broker ?? '',
        brokerContact: null
      }
    }));
  }, [setFormState, setValidationValue, setForceClearDatasourceState]);

  const onOperatingTerritoryChange = useCallback(value => {
    let operatingTerritory = value?.operatingTerritory;
    let territoryId = value?.id;

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        operatingTerritory,
        territoryId
      }
    }));
  }, [setFormState]);

  const onAssuredChange = useCallback(assured => {
    const selectedAssured = {
      assured: assured?.assured ?? '',
      assuredId: assured?.assuredId,
      effectiveFromDate: setZeroTime(assured?.effectiveFromDate),
      effectiveToDate: setZeroTime(assured?.effectiveToDate)
    };

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        assured: assured ? selectedAssured : null,
        secondAssured: previous.fields.isDualStamp ? selectedAssured : null
      }
    }));
  }, [setFormState]);

  const onReassuredChange = useCallback(value => {
    let reassured = value ?? '';

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        reassured,
        secondReassured: previous.fields.isDualStamp ? reassured : null
      }
    }));
  }, [setFormState]);

  const onExpiringPolicyChange = useCallback(policy => {
    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        policyId: policy?.policyId ?? '',
        expiringPolicyReferenceNumber: policy?.policyReferenceNumber ?? '',
        expiringProgrammeReference: policy?.programmeReference ?? '',
        placingBasis: policy?.placingBasis ?? '',
        placingBasisCode: policy?.placingType ?? '',
        declarationReference: policy?.declarationReference ?? '',
        umr: policy?.umr ?? ''
      }
    }));

    setRenewalState({ ...initialRenewalState });
  }, [initialRenewalState, setFormState, setRenewalState]);

  const getRequiredError = name => {
    return errors[name]?.type === 'required' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is required</span>;
  }

  const getAssuredRequiredError = (errorAlias, assuredType) => {
    if (errors[errorAlias]?.type === 'required') {
      return <span role="alert">"{GeneralDetailsFields[assuredType]}" is required</span>;
    }
    else if (errors[errorAlias]?.type === 'validForInceptionDate') {
      return  <span role="alert">Assured not valid for the selected Inception Date. Please leave Inception Date blank or select another Assured.</span>;
    }    
  }

  const getPlacingBasisError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">Selected "{GeneralDetailsFields.documentType}" cannot be associated with selected "{GeneralDetailsFields[name]}"</span>;
  }

  const getDateValidationError = name => {
    let validDate = errors[name]?.type === 'validDate' &&
      <span role="alert">"{GeneralDetailsFields[name]}" is not valid</span>;

    return validDate;
  }

  const getPolicyIsRenewedError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">"{GeneralDetailsFields.expiringPolicyReference}" has been renewed</span>;
  }

  const getProgrammeIsRenewedError = name => {
    return errors[name]?.type === 'validate' &&
      <span role="alert">All policy references in "{GeneralDetailsFields.expiringProgrammeReference}" have been renewed"</span>;
  }

  const renderRenewedPolicyCheckbox = () => {
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              onChange={(_event, checked) => onRenewedPolicyChecked(checked)}
              checked={fields.renewedPolicy}
            />
          }
          label={GeneralDetailsFields.renewedPolicy}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderSendTaskToOperations = () => {
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name="sendTaskToOperations"
              edge="end"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields.sendTaskToOperations}
            />
          }
          label={GeneralDetailsFields.sendTaskToOperations}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderUnderwriter = () => {
    return (
      <Grid item>
        <ComponentController
          name="underwriter"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields.underwriter}
              datasource={Datasource.underwritersData}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              abortController={null}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onUnderwriterChange(value); onChange(value); }}
              onBlur={onBlur}
            />
          }
        />
      </Grid>
    );
  }

  const renderYearOfAccount = () => {
    return (
      <Grid item>
        <ComponentController
          name="yoa"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                disabled={dropdownsData.years.length === 0}
                value={fields.yoa}
                data={dropdownsData.years}
                onChange={e => { onYOAChange(e.target?.value); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderEntity = () => {
    let data = dropdownsData.eclipseEntities.filter(x => x.yoa === fields.yoa).map(x => ({ Key: x.entityCode, Value: x.entityDisplayName }));
    let selectedValue = data.find(x => x.Key === fields.businessEntity)?.Value ?? '';

    return (
      <Grid item>
        <ComponentController
          name="businessEntity"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                disabled={!fields.yoa || !dropdownsData.eclipseEntities || dropdownsData.eclipseEntities.length === 0}
                value={selectedValue}
                data={data.map(x => x.Value)}
                onChange={e => { onEntityChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name)}
                shouldSetDefaultValue={true}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderPlacingBasis = () => {
    return (
      <Grid item>
        <ComponentController
          name="placingBasis"
          control={control}
          required
          rules={{ validate: placingBasis => validatePlacingBasis(placingBasis, fields.documentType, dropdownsData.documentTypes, dropdownsData.placingBases) }}
          render={({ field: { name, onBlur, onChange } }) =>
            <FormControl fullWidth error={!!errors[name]}>
              <Dropdown
                id={name}
                name={name}
                label={GeneralDetailsFields[name]}
                value={fields.placingBasis}
                data={dropdownsData.placingBases.map(p => p.name)}
                onChange={e => { onPlacingBasisChange(e); onChange(e); }}
                onBlur={onBlur}
                errorText={getRequiredError(name) || getPlacingBasisError(name)}
              />
            </FormControl>
          }
        />
      </Grid>
    );
  }

  const renderBrokerCode = () => {
    const getBrokerInfo = (broker) => broker ? `${broker.brokerCode} - ${broker.brokerPseud} - ${broker.broker}` : null;

    return (
      <Grid item>
        <ComponentController
          name="broker"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={getBrokerInfo(fields.broker)}
              forceClearDatasource={forceClearDatasourceState}
              datasource={Datasource.brokers}
              abortController={null}
              disabled={!fields.businessEntity}
              requestData={{
                syndicate: businessEntity.entityCode
              }}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onBrokerChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={4}><div>Broker Code</div></Grid>
                    <Grid item xs={4}><div>Broker Name</div></Grid>
                    <Grid item xs={4}><div>Broker Pseud</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={4}><div>{option.brokerCode}</div></Grid>
                    <Grid item xs={4}><div>{option.broker}</div></Grid>
                    <Grid item xs={4}><div>{option.brokerPseud}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const renderBrokerContact = () => {
    return (
      <Grid item>
        <ComponentController
          name="brokerContact"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields.brokerContact}
              fieldName={name}
              value={fields.brokerContact}
              datasource={Datasource.brokerContacts}
              forceClearDatasource={forceClearDatasourceState}
              abortController={null}
              disabled={!fields.broker}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              config={{ autoSearch: true }}
              requestData={{
                brokerId: `${fields.broker?.brokerId}`
              }}
              setValidationValue={setValidationValue}
              setFormState={setFormState}
              onChange={value => { onFieldChange(name, value); onChange(value); }}
              Popup={BrokerContactPopup}
              onBlur={onBlur}
            />
          }
        />
      </Grid>
    );
  }

  const renderOperatingTerritory = () => {
    return (
      <Grid item>
        <PickerComponent
          label={GeneralDetailsFields.operatingTerritory}
          fieldName="operatingTerritory"
          value={fields.operatingTerritory}
          datasource={Datasource.operatingTerritory}
          abortController={null}
          setAbortController={setAbortController}
          setBannerState={setBannerState}
          onChange={value => { onOperatingTerritoryChange(value); }}
        />
      </Grid>
    );
  }

  const renderDeclineImmediately = () => {
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              name="declineImmediately"
              edge="end"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields.declineImmediately}
            />
          }
          label={GeneralDetailsFields.declineImmediately}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderAssured = () => {
    let assuredType = "assuredOrMasterAssured";
    let render = fields.placingBasisCode !== PlacingBases.Declaration;
    let requiredField = ReassuredPlacingBases.includes(fields.placingBasisCode);

     const validateAgainstInceptionDate = assured => {
       if (!fields.inceptionDate || !validateDate(fields.inceptionDate) || !assured) {
         return true;
       }

       return toLocalDateTime(fields.inceptionDate).getTime() >= toLocalDateTime(new Date(assured?.effectiveFromDate)).getTime() &&
              toLocalDateTime(fields.inceptionDate).getTime() <= toLocalDateTime(new Date(assured?.effectiveToDate)).getTime();
     }

    return render && (
      <Grid item>
        <ComponentController
          name="assured"
          control={control}
          required={!requiredField}
          rules={{
            validate: { validForInceptionDate: value => validateAgainstInceptionDate(value) }
          }}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[assuredType]}
              fieldName={name}
              value={fields.assured}
              datasource={Datasource.assuredNames}
              abortController={abortController}
              error={!!errors[name]}
              errorText={getAssuredRequiredError(name, assuredType)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              setAbortController={setAbortController}
              onChange={value => { onAssuredChange(value); onChange(value); }}
              onBlur={onBlur}
              Popup={AssuredPopup}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={8}><div>Assured</div></Grid>
                    <Grid item xs={4}><div>Country</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={8}><div>{option.assured}</div></Grid>
                    <Grid item xs={4}><div>{option.country}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    );
  }

  const renderReassured = () => {
    let render = ReassuredPlacingBases.includes(fields.placingBasisCode);
    
    return render && (
      <Grid item>
        <ComponentController
          name="reassured"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields.reassured}
              abortController={abortController}
              datasource={Datasource.reassured}
              setAbortController={setAbortController}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              setValidationValue={setValidationValue}
              setLoading={setLoading}
              setFormState={setFormState}
              onChange={value => { onReassuredChange(value?.reassured); onChange(value); }}
              onBlur={onBlur}
              Popup={ReassuredPopup}
            />
          }
        />
      </Grid>
    );
  }

  const renderCoverHolder = () => {
    let render = [PlacingBases.Binder, PlacingBases.BinderNonProportionalRI, PlacingBases.BinderProportionalRI, PlacingBases.MasterBinder].includes(fields.placingBasisCode);
    
    return render && (
      <Grid item>
        <ComponentController
          name="coverHolder"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields[name]}
              fieldName={name}
              value={fields.coverHolder}
              datasource={Datasource.coverHolder}
              abortController={null}
              error={!!errors[name]}
              errorText={getRequiredError(name)}
              setBannerState={setBannerState}
              onChange={value => { onFieldChange(name, value?.coverHolder); onChange(value); }}
              onBlur={onBlur}
            />
          }
        />
      </Grid>
    );
  }

  const renderEplacement = () => {
    return (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="placementMethod"
            label={GeneralDetailsFields.eplacement}
            value={fields.eplacement}
            data={dropdownsData.eplacements}
            onChange={e => onFieldChange("eplacement", e.target.value)}
          />
        </FormControl>
      </Grid>
    );
  }

  const renderTypeOfModellingRisk = () => {
    return (
      <Grid item>
        <FormControl fullWidth>
          <Dropdown
            id="typeOfModellingRisk"
            name="typeOfModellingRisk"
            label={GeneralDetailsFields.typeOfModellingRisk}
            value={fields.typeOfModellingRisk}
            emptyValue={true}
            data={dropdownsData.typesOfModellingRisk}
            onChange={e => onFieldChange(e.target?.name, e.target?.value)}
          />
        </FormControl>
      </Grid>
    );
  }

  const renderInceptionDate = () => {
    const validateYear = date => {
      if (!date || !fields.yoa) {
        return true;
      }

      return date.getFullYear() === fields.yoa;
    }

    return (
      <Grid item>
        <ComponentController
          name="inceptionDate"
          control={control}
          rules={
            {
              validate: { validDate: value => validateDate(value) && validateYear(value) }
            }}
          render={({ field: { name, onChange, onBlur } }) =>
            <DatePickerElement
              id={name}
              name={name}
              label={GeneralDetailsFields[name]}
              fullWidth
              format="dd/MM/yyyy"
              value={fields.inceptionDate}
              onChange={e => { onFieldChange(name, e); onChange(e); }}
              onBlur={onBlur}
              error={!!errors[name]}
              helperText={getDateValidationError(name)}
            />
          }
        />
      </Grid>
    );
  }

  const renderConditionCode = () => {
    let render = fields.documentType === DocumentTypes.FirmOrderOnly;

    return render && (
      <ConditionCode
        dropdownsData={dropdownsData}
        fields={fields}
        control={control}
        errors={errors}
        trigger={trigger}
        setFormState={setFormState}
        setBannerState={setBannerState}
        validationRef={validationRef}
        isRequired={true}
      />
    );
  }

  const renderPricingNotRequired = () => {
    let render = fields.documentType === DocumentTypes.FirmOrderOnly;

    return render && (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              name="pricingNotRequired"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields.pricingNotRequired}
            />
          }
          label={GeneralDetailsFields.pricingNotRequired}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderEeaRisk = () => {
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <CheckboxElement
              edge="end"
              name="eeaRisk"
              onChange={(e, checked) => onFieldChange(e.target?.name, checked)}
              checked={fields.eeaRisk}
            />
          }
          label={GeneralDetailsFields.eeaRisk}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const onExpiringReferenceSwitch = useCallback(checked => {
    setValidationValue("expiringPolicyReferenceNumber", "");
    setValidationValue("expiringProgrammeReference", "");

    setFormState(previous => ({
      ...previous,
      fields: {
        ...previous.fields,
        expiringReferenceSwitch: checked,
        expiringPolicyReferenceNumber: '',
        expiringProgrammeReference: ''
      }
    }));
  }, [setFormState, setValidationValue]);

  const renderExpiringReferenceSwitch = () => {
    const name = "expiringReferenceSwitch";
    return (
      <Grid item>
        <FormControlWrapper
          control={
            <SwitchComponent
              name={name}
              checked={fields.expiringReferenceSwitch}
              onChange={e => onExpiringReferenceSwitch(e.target?.checked)}
            />
          }
          label={GeneralDetailsFields.expiringReferenceSwitch}
          labelPlacement="start"
        />
      </Grid>
    );
  }

  const renderExpiringPolicyReference = () => {
    let render = !fields.expiringReferenceSwitch;
    return render && <>
      <Grid item>
        <ComponentController
          name="expiringPolicyReferenceNumber"
          control={control}
          required
          rules={{ validate: () => !fields.isExpiringPolicyRenewed }}
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields.expiringPolicyReference}
              fieldName="policyReferenceNumber"
              value={fields.expiringPolicyReferenceNumber}
              datasource={Datasource.expiringPolicies}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              error={!!errors[name]}
              errorText={getRequiredError(name) || getPolicyIsRenewedError(name)}
              config={{ pickerViewClass: "assured-picker-view" }}
              setAbortController={setAbortController}
              setBannerState={setBannerState}
              setLoading={setLoading}
              setFormState={setFormState}
              setValidationValue={setValidationValue}
              onChange={value => { onExpiringPolicyChange(value); onChange(value); }}
              onBlur={onBlur}
              OptionsHeader={({ className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={2}><div>Organisation Name</div></Grid>
                    <Grid item xs={1}><div>Organisation Type</div></Grid>
                    <Grid item xs={1}><div>Entity</div></Grid>
                    <Grid item xs={1}><div>Major Class</div></Grid>
                    <Grid item xs={1}><div>YOA</div></Grid>
                    <Grid item xs={1}><div>Policy Reference</div></Grid>
                    <Grid item xs={1}><div>Policy Status</div></Grid>
                    <Grid item xs={1}><div>Country</div></Grid>
                    <Grid item xs={1}><div>UMR</div></Grid>
                    <Grid item xs={1}><div>Dec Ref</div></Grid>
                    <Grid item xs={1}><div>Placing Basis</div></Grid>
                  </Grid>
                );
              }}
              OptionView={({ option, className }) => {
                return (
                  <Grid container direction="row" spacing={0} className={className}>
                    <Grid item xs={2}><div>{option.assured}</div></Grid>
                    <Grid item xs={1}><div>{option.assuredType}</div></Grid>
                    <Grid item xs={1}><div>{option.entity}</div></Grid>
                    <Grid item xs={1}><div>{option.majorClassCode}</div></Grid>
                    <Grid item xs={1}><div>{option.yearOfAccount}</div></Grid>
                    <Grid item xs={1}><div>{option.policyReferenceNumber}</div></Grid>
                    <Grid item xs={1}><div>{option.policyStatus}</div></Grid>
                    <Grid item xs={1}><div>{option.country}</div></Grid>
                    <Grid item xs={1}><div>{option.umr}</div></Grid>
                    <Grid item xs={1}><div>{option.declarationReference}</div></Grid>
                    <Grid item xs={1}><div>{option.placingBasis}</div></Grid>
                  </Grid>
                );
              }}
            />
          }
        />
      </Grid>
    </>;
  }

  const renderExpiringProgrammeReference = () => {
    let render = fields.expiringReferenceSwitch;
    return render && (
      <Grid item>
        <ComponentController
          name="expiringProgrammeReference"
          control={control}
          required
          render={({ field: { name, onBlur, onChange } }) =>
            <PickerComponent
              label={GeneralDetailsFields.expiringProgrammeReference}
              fieldName="programmeReference"
              value={fields.expiringProgrammeReference}
              datasource={Datasource.programmes}
              requestData={{
                BusinessEntities: dropdownsData.entitiesForFilter
              }}
              error={!!errors[name]}
              errorText={getRequiredError(name) || getProgrammeIsRenewedError(name)}
              setAbortController={setAbortController}
              setBannerState={setBannerState}
              onChange={value => { onExpiringPolicyChange(value); onChange(value); }}
              onBlur={onBlur}
            />
          }
        />
      </Grid>
    );
  }

  const renderLookupForMasterAssuredAndPolicyReference = (
    lookupType = "lookupForMasterAssuredAndMasterPolicyReference"
  ) => {
    let entityFilter = isDualStampEntity(fields.businessEntity) ? [S4000Entity.DisplayValue] : [fields.businessEntity];

    return (<MasterPolicyReferenceControl
      entities={entityFilter}
      fields={fields}
      control={control}
      errors={errors}
      lookupType={lookupType}
      forceClearDatasourceState={forceClearDatasourceState}
      setBannerState={setBannerState}
      setLoading={setLoading}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
      abortController={abortController}
      setAbortController={setAbortController }
    />);
  }

  const renderMasterPolicyReferenceNumber = (name = "masterPolicyReferenceNumber") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields.masterPolicyReferenceNumber ?? ""}
        />
      </Grid>
    );
  }

  const renderMasterAssured = (name = "_masterAssuredOrMasterReassured") => {
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields._masterAssuredOrMasterReassured ?? ""}
        />
      </Grid>
    );
  }

  const renderSecondLookupForMasterAssuredAndPolicyReference = (
    lookupType = "secondLookupForMasterAssuredAndMasterPolicyReference"
  ) => {
    return (<SecondMasterPolicyReferenceControl
      entities={["HDAC"]}
      fields={fields}
      control={control}
      errors={errors}
      lookupType={lookupType}
      forceClearDatasourceState={forceClearDatasourceState}
      setBannerState={setBannerState}
      setLoading={setLoading}
      setFormState={setFormState}
      setValidationValue={setValidationValue}
    />);
  }

  const renderSecondMasterPolicyReferenceNumber = () => {
    let name = "secondMasterPolicyReferenceNumber";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          label={GeneralDetailsFields[name]}
          fullWidth
          disabled
          value={fields.secondMasterPolicyReferenceNumber ?? ""}
        />
      </Grid>
    );
  }

  const renderSecondMasterAssured = () => {
    let name = "_secondMasterAssuredOrMasterReassured";
    return (
      <Grid item>
        <TextField
          id={name}
          name={name}
          fullWidth
          disabled
          label={GeneralDetailsFields[name]}
          value={fields._secondMasterAssuredOrMasterReassured ?? ""}
        />
      </Grid>
    );
  }

  const renderBrokerSection = () => {
    let render = fields.placingBasisCode !== PlacingBases.Declaration;

    return render && <>
      {renderBrokerCode()}
      {renderBrokerContact()}
    </>;
  }

  const renderMasterPolicySection = () => {
    if (fields.placingBasisCode !== PlacingBases.Declaration) {
      return false;
    }

    if (!isDualStampEntity(fields.businessEntity)) {
      return <>
        {renderLookupForMasterAssuredAndPolicyReference()}
        {renderMasterPolicyReferenceNumber()}
        {renderMasterAssured()}
      </>;
    }

    return <>
      {renderLookupForMasterAssuredAndPolicyReference("lookupForMasterAssuredAndMasterPolicyReference4000")}
      {renderMasterPolicyReferenceNumber("masterPolicyReferenceNumber4000")}
      {renderMasterAssured("_masterAssuredOrMasterReassured4000")}
      {renderSecondLookupForMasterAssuredAndPolicyReference()}
      {renderSecondMasterPolicyReferenceNumber()}
      {renderSecondMasterAssured()}
    </>;
  }

  const renderSubmissionPolicySection = () => {
    const render = !fields.renewedPolicy;
    return render && <>
      {renderSendTaskToOperations()}
      {renderUnderwriter()}
      {renderYearOfAccount()}
      {renderEntity()}
      {renderPlacingBasis()}
      {renderBrokerSection()}
      {renderOperatingTerritory()}
      {renderDeclineImmediately()}
      {renderAssured()}
      {renderMasterPolicySection()}
      {renderReassured()}
      {renderCoverHolder()}
      {renderEplacement()}
      {renderTypeOfModellingRisk()}
      {renderInceptionDate()}
      {renderConditionCode()}
      {renderPricingNotRequired()}
      {renderEeaRisk()}
    </>;
  }

  const renderRenewedPolicySection = () => {
    const render = fields.renewedPolicy;
    return render && <>
      {renderExpiringReferenceSwitch()}
      {renderExpiringPolicyReference()}
      {renderExpiringProgrammeReference()}
      {renderEplacement()}
      {renderTypeOfModellingRisk()}
      {renderEeaRisk()}
    </>;
  }

  return <>
    {renderRenewedPolicyCheckbox()}
    {renderRenewedPolicySection()}
    {renderSubmissionPolicySection()}
  </>;
}

export default SubmissionSection;