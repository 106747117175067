import React, { useEffect, useState, useCallback } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { FormControl, Grid } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { Datasource } from 'Api';

import { getClassesOfBusiness, getRenewPolicies, getPolicy } from 'components/SubmissionWizard/SubmissionWizard.api';
import {
  BannerValidationMessage, getInceptionDate, getUkFormattedDateString,
  isEntityField, RenewDeclaration, DeclarationPlacingType, DefauldUnderwriter, UmrField,
  isPolicyRenewedWithCustomLink, RenewalLinkReference, CustomLinkType
} from 'components/SubmissionWizard/Helpers';
import { BannerType, CheckboxElement, Dropdown, PickerComponent, DatePickerElement } from 'components/common';

import './Renewals.css';

export const Renewals = ({
  renewalState,
  generalDetailsState,
  validationRef,
  setRenewalState,
  setBannerState,
  setLoading,
  declarationTypes
}) => {
  const [abortController, setAbortController] = useState(new AbortController());

  const theme = createMuiTheme({
    typography: {
      fontSize: 12
    }
  });

  const columnsConfig = [
    {
      field: 'renewed',
      headerName: 'Renew',
      flex: 0.65,
      datasource: 'renew',
      type: "actions",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        let data = getCheckboxContainerData(params);
        let name = data.source;

        return data && (
          <CheckboxElement
            edge="end"
            name={name}
            onChange={(e, checked) => { onCheckboxChange(name, data.id, checked); }}
            checked={data.isChecked}
          />
        );
      }
    },
    {
      field: 'layer',
      headerName: 'Layer',
      flex: 0.55,
      type: "singleSelect",
      editable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true
    },
    {
      field: 'layerSection',
      headerName: 'Section',
      flex: 0.65,
      type: "string",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      editable: false
    },
    {
      field: 'uw',
      headerName: 'UW',
      flex: 0.5,
      type: "singleSelect",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      underwriterContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'year',
      headerName: 'Year',
      flex: 0.55,
      type: "string",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      editable: false,
      mandatory: true
    },
    {
      field: 'entityCode',
      previousFields: ['year'],
      dependentFields: ['classType', 'majorClass', 'minorClass', 'class'],
      headerName: 'Entity',
      flex: 0.6,
      type: "singleSelect",
      editable: true,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'classType',
      previousFields: ['entityCode', 'year'],
      dependentFields: ['majorClass', 'minorClass', 'class'],
      headerName: 'Class Type',
      flex: 0.85,
      type: "singleSelect",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'majorClass',
      previousFields: ['classType', 'entityCode', 'year'],
      dependentFields: ['minorClass', 'class'],
      headerName: 'Major Class',
      flex: 0.85,
      type: "singleSelect",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'minorClass',
      previousFields: ['majorClass', 'classType', 'entityCode', 'year'],
      dependentFields: ['class'],
      headerName: 'Minor Class',
      flex: 0.85,
      type: "singleSelect",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'class',
      previousFields: ['minorClass', 'majorClass', 'classType', 'entityCode', 'year'],
      headerName: 'Class',
      flex: 0.6,
      type: "singleSelect",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      dropdownContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'inceptionDate',
      headerName: 'Inception Date',
      flex: 1,
      type: "date",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      inceptionDateContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true,
      valueFormatter: params => getUkFormattedDateString(params?.value)
    },
    {
      field: 'brokerNo',
      headerName: 'Broker No',
      flex: 0.8,
      type: "string",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      brokerNoContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'brokerContact',
      headerName: 'Broker Contact',
      flex: 1,
      type: "string",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      brokerContactContainer: true,
      editable: true,
      renderEditCell: {},
      mandatory: true
    },
    {
      field: 'umr',
      headerName: 'UMR',
      flex: 0.5,
      type: "string",
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      editable: true,
      mandatory: false
    },
    {
      field: 'lead',
      headerName: 'Lead',
      flex: 0.53,
      datasource: 'lead',
      editable: true,
      sortable: false,
      resizable: false,
      type: "actions",
      disableColumnMenu: true,
      renderCell: (params) => {
        let data = getCheckboxContainerData(params);
        let name = data.source;

        return data && (
          <CheckboxElement
            edge="end"
            name={name}
            onChange={(e, checked) => { onCheckboxChange(name, data.id, checked); }}
            checked={data.isChecked}
          />
        );
      }
    }
  ];

  const [renewalsModel, setRenewalsModel] = useState(renewalState);
  const [columns, setColumns] = useState(columnsConfig);

  let validateGrid = useCallback(() => {
    return renewalsModel.fields.filter(policy => policy.renewed).every(policy => columnsConfig
      .filter(c => c.mandatory)
      .every(c => !!policy[c.field])
    );
  }, [renewalsModel.fields, columnsConfig]);

  useEffect(() => {
    let showUserDialogWarning = renewalState.fields.some(
      x => x.year === DefauldUnderwriter.RenewalYear &&
        x.uw !== DefauldUnderwriter.Abbreviation &&
        x.renewed === true);

    setRenewalState(previous => ({ ...previous, showUserDialogWarning }));
  }, [renewalState.fields]);

  useEffect(() => {
    validationRef.current = async () => {    
      let message = BannerValidationMessage;
      let isValid = validateGrid();

      if (!renewalsModel.fields.some(x => x.renewed === true)) {
        isValid = false;
        message = "At least one policy must be selected";
      }

      if (!renewalsModel.fields.filter(policy => policy.umr).every(c => validateUmr(c, c.umr))) {
        isValid = false;
        message = "Please, specify the correct UMR";
      }

      if (!isValid) {
        setBannerState({
          show: true,
          type: BannerType.warning,
          message: message
        });
      }

      return isValid;
    };
  }, [validationRef, renewalsModel.fields, validateGrid, setBannerState])

  useEffect(() => {
    let columns = columnsConfig.map(c => {
      if (c.underwriterContainer) {
        c.renderEditCell = underwriterContainer;
      } else if (c.dropdownContainer) {
        c.renderEditCell = dropdownContainer;
      } else if (c.inceptionDateContainer) {
        c.renderEditCell = inceptionDateContainer;
      } else if (c.brokerNoContainer) {
        c.renderEditCell = brokerNoContainer;
      } else if (c.brokerContactContainer) {
        c.renderEditCell = brokerContactContainer;
      }

      if (c.mandatory) {
        c.headerName = `${c.headerName}*`;
      }

      return c;
    });

    setColumns(columns);
  }, [renewalsModel.fields]);

  useEffect(() => {
    let initRenewalsData = async () => {
      setLoading(true);

      let initRenewals = [];
      let dropdownsData = [];
      let response = await getRenewPolicies(generalDetailsState.fields.policyId);

      if (response.success) {
        let originalRenewals = response.data;

        for (var i = 0; i < originalRenewals.length; i++) {
          let renewal = originalRenewals[i];

          let inceptionDate = renewal.detail.expiryDate ? getInceptionDate(renewal.detail.expiryDate) : null;
          let year = inceptionDate?.getFullYear() ?? renewal.detail.yoa;

          let classesOfBusiness = await getClassesOfBusiness(renewal.detail.uw, year);
         
          let entityData = classesOfBusiness.data.find(z => z.entityCode === renewal.policyLines[0].synd &&
            z.yoa === year);
          let entity = entityData?.entityDescription;
          let entityCode = entity ? renewal.policyLines[0].synd : null;

          let classType = classesOfBusiness.data.find(z => z.classTypeCode === renewal.detail.class4 &&
            z.entityDescription === entity &&
            z.yoa === year)?.classTypeDescription;
          let majorClass = classesOfBusiness.data.find(z => z.majorClassCode === renewal.detail.class1 &&
            z.entityDescription === entity &&
            z.classTypeDescription === classType &&
            z.yoa === year)?.majorClassDescription;
          let minorClass = classesOfBusiness.data.find(z => z.minorClassCode === renewal.detail.class2 &&
            z.entityDescription === entity &&
            z.classTypeDescription === classType &&
            z.majorClassDescription === majorClass &&
            z.yoa === year)?.minorClassDescription;
          let classData = classesOfBusiness.data.find(z => z.classCode === renewal.detail.class3 &&
            z.entityDescription === entity &&
            z.classTypeDescription === classType &&
            z.majorClassDescription === majorClass &&
            z.minorClassDescription === minorClass &&
            z.yoa === year)?.classDescription;
          let yoaData = renewal.underwriterYoaList;
          let id = uuidv4();

          let row = {
            id: id,
            policyId: renewal.id,
            mainLayer: renewal.detail.mainLayerInd,
            layer: renewal.detail.layerNum,
            renewed: true,
            layerSection: renewal.detail.sectionCode,
            entityCode: entityCode,
            entity: entity,
            producingTeamCode: renewal.policyLines[0].producingTeam,
            year: year,
            uw: renewal.detail.uw,
            underwriterName: renewal.underwriterName,
            classTypeCode: renewal.detail.class4,
            classType: classType,
            majorClassCode: renewal.detail.class1,
            majorClass: majorClass,
            minorClassCode: renewal.detail.class2,
            minorClass: minorClass,
            classCode: renewal.detail.class3,
            class: classData,
            inceptionDate: inceptionDate ?? null,
            lloydsBrokerID: renewal.placers[0].lloydsBrokerID,
            brokerNo: renewal.placers[0].brokerCode,
            brokerContact: renewal.placers[0].contactName,
            brokerContactId: renewal.placers[0].brokerContactID,
            brokerPseud: renewal.placers[0].brokerPseud,
            broker: renewal.placers[0].brokerName,
            contractBasis: renewal.contractCertainty.contractBasis,
            lead: renewal.detail.leadInd,
            expiringPolicyReferenceNumber: renewal.detail.policyRef,
            entryType: renewal.detail.entryType,
            bureauIndicator: renewal.bureauIndicator,
            placingBasis: renewal.placingBasis,
            placingBasisCode: renewal.detail.placingType,
            assured: renewal.assured,
            assuredId: renewal.assuredId,
            reassured: renewal.reassuredOrganisation,
            coverholder: renewal.coverholder,
            obligor: renewal.obligor,
            territory: renewal.territory,
            policyStructure: renewal.policyStructure
          };

          if (renewal.detail.placingType === DeclarationPlacingType) {          
            let masterPolicy = await getPolicy(renewal.detail.parentPolicyId);
            let isMasterNotRenewed = !(!!masterPolicy?.data?.detail?.renewedToId || isPolicyRenewedWithCustomLink(masterPolicy.data));
           
            let isDeclaration = true;
            let coverPolicy = '';
            let coverPolicyID = null;
            let year = masterPolicy.data?.detail?.yoa;
            
            let masterAssured = masterPolicy.data?.assuredOrganisations[0]?.orgName;
            let masterReassured = masterPolicy.data?.reassured[0]?.orgName;
            
            if (isMasterNotRenewed) {
              coverPolicy = masterPolicy.data?.detail?.policyRef;
              coverPolicyID = renewal.detail.parentPolicyId;
            } else {
              let renewLink;
              if(!masterPolicy?.data?.detail?.renewedToId){
                renewLink =  masterPolicy.data?.policyLinks?.find(
                  pl => pl.linkType?.toLowerCase() === CustomLinkType &&
                  pl.linkRef?.toLowerCase() === RenewalLinkReference && 
                  pl.referencedPolicies[0]?.policyId > masterPolicy.data?.detail?.id);
              }
              coverPolicy = masterPolicy.data?.detail?.renewedToRef ?? renewLink?.referencedPolicies[0]?.policyLineRef;
              coverPolicyID = masterPolicy.data?.detail?.renewedToId ?? renewLink?.referencedPolicies[0]?.policyId;
              let newMasterPolicy = await getPolicy(coverPolicyID);
              if(!masterPolicy?.data?.detail?.renewedToId){
                 masterAssured = newMasterPolicy.data?.assuredOrganisations[0]?.orgName;
                 masterReassured = newMasterPolicy.data?.reassured[0]?.orgName;
              }
              
              year = newMasterPolicy.data?.detail?.yoa;
            }

            let declarationTypeData = !!renewal.detail.decRef
              ? declarationTypes.find(x => x.renewAs === RenewDeclaration.ShortDeclaration)
              : declarationTypes.find(x => x.renewAs === RenewDeclaration.LongDeclaration);
            let renewAs = declarationTypeData.renewAs;            
            let declarationTypeCode = declarationTypeData.declarationTypeCode;
            let declarationType = declarationTypeData.declarationTypeDescription;
            let declarationAssured = renewal.assured;
            row = {
              ...row,
              year,
              isMasterNotRenewed,
              coverPolicy,
              coverPolicyID,
              renewAs,
              masterPolicyReferenceNumber: coverPolicy,
              masterAssured,
              masterReassured,
              declarationTypeCode,
              declarationType,
              declarationAssured,
              isDeclaration
            }
          }

          initRenewals = [...initRenewals, row];

          dropdownsData = [
            ...dropdownsData,
            {
              id: id,
              yoa: yoaData,
              classesOfBusiness: classesOfBusiness.data
            }];
        }

        setRenewalsModel(previous => ({ ...previous, fields: initRenewals, dropdownsData, originalRenewals }));
        setRenewalState(previous => ({ ...previous, fields: initRenewals, dropdownsData, originalRenewals }));
      }

      setLoading(false);
    }

    if (renewalState.fields.length === 0 && renewalState.dropdownsData.length === 0) {
      initRenewalsData();
    }
  }, []);

  const validateUmr = (policy, umr) => {
    let brokerLen = (policy.brokerNo.toString().length >= UmrField.MinLength) ? policy.brokerNo.toString().length : UmrField.MinLength;
    let umrRegex = new RegExp(`^(B|b)[0-9]{${brokerLen}}[aA-zZ|0-9]{0,${UmrField.MaxLength - brokerLen}}$`);
    let brokerCodeRegex = new RegExp(`^(B|b)[0-9]{${brokerLen}}`);

    let isMatch = umrRegex.test(umr);
    if (isMatch && policy.bureauIndicator) {
      let match = umr.match(brokerCodeRegex);
      if (match) {
        let brokerCode = match[0].trim().replace('B', '');
        let brokerNo = parseInt(brokerCode, 10)
        if (brokerNo) {
          return policy.brokerNo === brokerNo;
        }
      }
      return false;
    }
    return isMatch;
  }

  const getCommonData = useCallback(params => {
    let data = renewalsModel.fields.length > 0 ? renewalsModel.fields.find(x => x.id === params.id) : [];
    let source = params.colDef.field;
    let id = params.id;

    return {
      data: data,
      source: source,
      id: id
    }
  }, [renewalsModel.fields]);

  const getCheckboxContainerData = params => {
    let commonData = getCommonData(params);
    let isChecked = commonData.data[commonData.source];

    return {
      isChecked: isChecked,
      source: commonData.source,
      id: commonData.id
    }
  }

  const getBrokerNoContainerData = params => {
    let commonData = getCommonData(params);
    let entity = commonData.data.entityCode;
    let value = commonData.data[commonData.source];

    return {
      source: commonData.source,
      id: commonData.id,
      entity: entity,
      value: value
    }
  }

  const getBrokerContactContainerData = params => {
    let commonData = getCommonData(params);
    let brokerId = commonData.data.lloydsBrokerID;
    let value = commonData.data[commonData.source];

    return {
      source: commonData.source,
      id: commonData.id,
      brokerId: brokerId,
      value: value
    }
  }

  const getUnderwriterContainerData = params => {
    let commonData = getCommonData(params);
    let value = commonData.data[commonData.source];
    let year = commonData.data["year"];

    return {
      source: commonData.source,
      id: commonData.id,
      value: value,
      year
    }
  }

  const getInceptionDateContainerData = params => {
    let commonData = getCommonData(params);
    let value = commonData.data[commonData.source];

    return {
      source: commonData.source,
      id: commonData.id,
      value: value
    }
  }

  const getDropdownContainerContainerData = params => {
    let commonData = getCommonData(params);

    let fieldName = commonData.source;
    let id = commonData.id;
    let currentPolicy = commonData.data;
    let data = renewalsModel.dropdownsData.find(x => x.id === params.id)?.classesOfBusiness;

    let previousField = params.colDef.previousFields[0];
    let isEnable = !!currentPolicy[previousField];

    params.colDef.previousFields.forEach(field => {
      let selector = isEntityField(field) ? field : `${field}Code`;

      if (currentPolicy[selector]) {
        data = data.filter(item => item[selector] === currentPolicy[selector]);
      }
    });

    let dropdownsData = data.reduce((array, record) => {
      let isEntityColumn = isEntityField(fieldName);
      let code = isEntityColumn ? record['entityDescription'] : record[`${fieldName}Code`];
      let description = isEntityColumn ? record[fieldName] : record[`${fieldName}Description`];

      if (!array.some(i => i.code === code)) {
        array.push({ code, description });
      }

      return array;
    }, []);

    let value = commonData.data ? commonData.data[fieldName] : "";

    return { fieldName, id, value, isEnable, dropdownsData };
  }

  const onCheckboxChange = useCallback((name, id, checked) => {
    let fields = renewalsModel.fields.map(x => {
      if (x.id === id) {
        x[name] = checked;
      }
      return x;
    });

    setRenewalsModel(previous => ({ ...previous, fields }));
    setRenewalState(previous => ({ ...previous, fields }));
  }, [setRenewalState, setRenewalsModel, renewalsModel.fields]);

  const onUnderwriterChange = useCallback(async (underwriterData, id) => {
    let underwriter = underwriterData ?
      {
        underwriter: underwriterData.underwriter,
        underwriterAbbreviation: underwriterData.underwriterAbbreviation
      } : {};
    let yoaList = underwriterData ? underwriterData.yoaList : [];
    let year = renewalsModel.fields.find(x => x.id === id).year;
    let fields = renewalsModel.fields.map(x => {
      if (x.id === id) {
        x.uw = underwriter?.underwriterAbbreviation ?? "";
        x.underwriterName = underwriter?.underwriter ?? "";      
      }

      return x;
    });

    if (underwriterData &&
       !(underwriterData.underwriterAbbreviation === DefauldUnderwriter.Abbreviation && year === DefauldUnderwriter.RenewalYear)) {
      fields = renewalsModel.fields.map(x => {
        if (x.id === id) {
          x.entity = "";
          x.year = null;
          x.classType = "";
          x.majorClass = "";
          x.minorClass = "";
          x.class = "";
          x.entityCode = "";
          x.classTypeCode = "";
          x.majorClassCode = "";
          x.minorClassCode = "";
          x.classCode = "";
          x.inceptionDate = null;
          x.brokerNo = "";
          x.lloydsBrokerID = "";
          x.brokerContact = "";
          x.brokerContactId = "";
          x.brokerPseud = "";
          x.umr = "";
        }

        return x;
      });
    }    

    if (underwriterData) {
      let dropdownsData = renewalsModel.dropdownsData.map(x => {
        if (x.id === id) {
          x.yoa = yoaList
        }

        return x;
      });

      setRenewalsModel(previous => ({ ...previous, fields, dropdownsData }));
      setRenewalState(previous => ({ ...previous, fields, dropdownsData }));
    }
    else {
      setRenewalsModel(previous => ({ ...previous, fields }));
      setRenewalState(previous => ({ ...previous, fields }));
    }
  }, [renewalsModel, setRenewalState, setRenewalsModel]);

  const onInceptionDateChange = useCallback(async (value, id) => {
    let yoa = renewalsModel.dropdownsData.find(x => x.id === id)?.yoa;
    let dateYear = value?.getFullYear();

    if (dateYear) {
      if (!yoa.includes(dateYear)) {
        setBannerState({
          show: true,
          type: BannerType.error,
          message: "This year doesn't exist for Underwriter"
        });

        return;
      }

      let renew = renewalsModel.fields.find(x => x.id === id);
      let isClassesofBusinessUpdate = renew.year !== dateYear;

      let fields = renewalsModel.fields.map(x => {
        if (x.id === id) {
          if (isClassesofBusinessUpdate) {
            x.entity = "";
            x.entityCode = "";
            x.majorClass = "";
            x.majorClassCode = "";
            x.minorClass = "";
            x.minorClassCode = "";
            x.class = "";
            x.classCode = "";
            x.classType = "";
            x.classTypeCode = "";
            x.brokerNo = "";
            x.lloydsBrokerID = "";
            x.brokerContact = "";
            x.brokerContactId = "";
            x.brokerPseud = "";
          }

          x.inceptionDate = value;
          x.year = dateYear;
        }

        return x;
      });

      let classesOfBusiness = isClassesofBusinessUpdate ? await getClassesOfBusiness(renew.uw, dateYear) : null;
      let dropdownsData = renewalsModel.dropdownsData.map(x => {
        if (x.id === id) {
          x.classesOfBusiness = classesOfBusiness?.data ?? x.classesOfBusiness;
        }

        return x;
      });

      setRenewalsModel(previous => ({ ...previous, fields, dropdownsData }));
      setRenewalState(previous => ({ ...previous, fields, dropdownsData }));
    }
  }, [renewalsModel, setRenewalState, setRenewalsModel, setBannerState]);

  const onCellChange = useCallback(params => {
    let fields = renewalsModel.fields.map(x => {
      if (x.id in params) {
        let umr = params[x.id].umr;

        if (umr) {
          x['umr'] = umr.value;
        }
      }

      return x;
    });

    setRenewalsModel(previous => ({ ...previous, fields }));
    setRenewalState(previous => ({ ...previous, fields }));
  }, [setRenewalState, setRenewalsModel, renewalsModel.fields]);

  const onDropdownChanged = useCallback((cellName, value, data, dependentFields) => {
    let id = data?.id;

    let fields = renewalsModel.fields.map(x => {
      if (x.id === id) {
        let code = isEntityField(cellName) ? 'entity' : `${cellName}Code`;
        x[code] = data.dropdownsData.find(r => r.description === value).code;
        x[cellName] = value;

        if (isEntityField(cellName)) {
          x.lloydsBrokerID = "";
          x.brokerNo = "";
          x.brokerContact = "";
          x.brokerContactId = "";
          x.brokerPseud = "";
          x.umr = "";
        }

        if (dependentFields) {
          dependentFields.forEach(field => {
            let fieldSelector = isEntityField(field) ? field : `${field}Code`;

            x[fieldSelector] = x[field] = '';
          });
        }
      }

      return x;
    });

    setRenewalsModel(previous => ({ ...previous, fields }));
    setRenewalState(previous => ({ ...previous, fields }));
  }, [renewalsModel, setRenewalState, setRenewalsModel]);

  const onBrokerNoChanged = useCallback((value, id) => {
    let fields = renewalsModel.fields.map(x => {
      if (x.id === id) {
        x.lloydsBrokerID = value?.brokerId ?? '';
        x.brokerNo = value?.brokerCode ?? '';
        x.brokerPseud = value?.brokerPseud ?? '';
        x.broker = value?.broker ?? '';
        x.brokerContact = '';
        x.brokerContactId = '';
        x.umr = '';
      }

      return x;
    });

    setRenewalsModel(previous => ({ ...previous, fields }));
    setRenewalState(previous => ({ ...previous, fields }));
  }, [setRenewalState, setRenewalsModel, renewalsModel])

  const onBrokerContactChanged = useCallback((value, id) => {
    let fields = renewalsModel.fields.map(x => {
      if (x.id === id) {
        x.brokerContact = value?.brokerContact ?? '';
        x.brokerContactId = value?.brokerContactId ?? '';
      }

      return x;
    });

    setRenewalsModel(previous => ({ ...previous, fields }));
    setRenewalState(previous => ({ ...previous, fields }));
  }, [setRenewalState, setRenewalsModel, renewalsModel.fields])

  const underwriterContainer = params => {
    let data = getUnderwriterContainerData(params);
    let name = data.source;
    let year = data.year;

    return (
      <FormControl fullWidth>
        <PickerComponent
          fieldName={name}
          value={data.value}
          datasource={Datasource.underwritersData}
          config={{ pickerViewClass: "renew-grid-picker-view" }}
          setAbortController={setAbortController}
          setBannerState={setBannerState}
          onChange={value => { onUnderwriterChange(value, data.id); }}
          OptionsHeader={({ className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={4}><div>UW</div></Grid>
                <Grid item xs={8}><div>Underwriter Name</div></Grid>
              </Grid>
            );
          }}
          OptionView={({ option, className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={4}><div>{option.underwriterAbbreviation}</div></Grid>
                <Grid item xs={8}><div>{option.underwriter}</div></Grid>
              </Grid>
            );
          }}
        />
      </FormControl>
    );
  }

  const inceptionDateContainer = params => {
    let data = getInceptionDateContainerData(params);
    let name = data.source;

    return data && (
      <FormControl fullWidth>
        <DatePickerElement
          id={data.id}
          name={name}
          disableMargin={true}
          format="dd/MM/yyyy"
          value={data.value}
          onChange={e => { onInceptionDateChange(e, data.id); }}
        />
      </FormControl>
    );
  }

  const brokerNoContainer = params => {
    let data = getBrokerNoContainerData(params);
    let name = data.source;

    return data.entity && (
      <FormControl fullWidth>
        <PickerComponent
          fieldName={name}
          value={`${data.value}`}
          datasource={Datasource.brokers}
          config={{ pickerViewClass: "renew-grid-picker-view" }}
          requestData={{
            syndicate: data.entity
          }}
          setBannerState={setBannerState}
          onChange={value => { onBrokerNoChanged(value, data.id); }}
          OptionsHeader={({ className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={4}><div>Broker Code</div></Grid>
                <Grid item xs={4}><div>Broker Name</div></Grid>
                <Grid item xs={4}><div>Broker Pseud</div></Grid>
              </Grid>
            );
          }}
          OptionView={({ option, className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={4}><div>{option.brokerCode}</div></Grid>
                <Grid item xs={4}><div>{option.broker}</div></Grid>
                <Grid item xs={4}><div>{option.brokerPseud}</div></Grid>
              </Grid>
            );
          }}
        />
      </FormControl>
    );
  }

  const brokerContactContainer = params => {
    let data = getBrokerContactContainerData(params);
    let name = data.source;

    return data.brokerId && (
      <FormControl fullWidth>
        <PickerComponent
          fieldName={name}
          value={data.value}
          datasource={Datasource.brokerContacts}
          config={{ pickerViewClass: "renew-grid-picker-view", autoSearch: true }}
          setBannerState={setBannerState}
          requestData={{
            brokerId: `${data.brokerId}`
          }}
          onChange={value => { onBrokerContactChanged(value, data.id); }}
          OptionView={({ option, className }) => {
            return (
              <Grid container direction="row" spacing={0} className={className}>
                <Grid item xs={12}><div>{option.brokerContact}</div></Grid>
              </Grid>
            );
          }}
        />
      </FormControl>
    );
  }

  const dropdownContainer = params => {
    let data = getDropdownContainerContainerData(params);
    let name = data.fieldName;
    let options = data.dropdownsData.map(r => r.description);
    let dependentFields = params.colDef.dependentFields;

    return data.isEnable && (
      <FormControl fullWidth>
        <Dropdown
          id={name}
          name={name}
          label={data.value}
          value={data.value}
          data={options}
          onChange={e => onDropdownChanged(e.target.name, e.target.value, data, dependentFields)}
          hideLabel={true}
          shouldSetDefaultValue={true}
        />
      </FormControl>
    );
  }

  const renderRenewalTable = () => {
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <DataGrid
            rows={renewalsModel.fields}
            columns={columns}
            density="compact"
            hideFooter={true}
            autoHeight={true}
            editMode="row"
            onEditRowsModelChange={onCellChange}
          />
        </MuiThemeProvider>
      </div>
    );
  }

  return <>
    <Grid item container xs={12} direction="column">
      {renderRenewalTable()}
    </Grid>
  </>;
}